import React, { useState, useEffect, useContext, useRef } from "react";
import { SocketContext } from "../../app/socket";
import { useSelector } from "react-redux";
import { BiCheck, BiX, BiTrash, BiDotsHorizontal } from "react-icons/bi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { motion } from "framer-motion";
import FullSizeImg from "../FullSizeImg/fullSizeImg";
import { iconStyle } from "../../utils/generalUtils";
import { useTranslation } from "react-i18next";

function AnnouncementItem({ announcement, setAnnouncementToEdit }) {
  const socketContext = useContext(SocketContext);
  const { t } = useTranslation();
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const profile = useSelector((state) => state.profile.value);
  const [createdBy, setCreatedBy] = useState({});
  const [deleted, setDeleted] = useState(false);
  const deleteOptionsRef = useRef(null);
  const deleteInitRef = useRef(null);

  useEffect(() => {
    if (announcement.student_ids && !Array.isArray(announcement.student_ids)) {
      announcement.student_ids = announcement.student_ids
        .split(",")
        .map(Number);
    }
    imageClickListener();
    return getCreatedBy();
  }, [announcement]);

  useEffect(() => {
    if (deleted) {
      setShowDeleteOptions(false);
    }
  }, [deleted]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteOptionsRef.current &&
        !deleteOptionsRef.current.contains(event.target) &&
        deleteInitRef.current &&
        !deleteInitRef.current.contains(event.target)
      ) {
        setShowDeleteOptions(false);
      }
    };

    if (showDeleteOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDeleteOptions]);

  const deleteClick = () => {
    setShowDeleteOptions(true);
  };

  const deleteAnnouncement = () => {
    setDeleted(true);
    const body = {
      announcement_id: announcement.announcement_id,
    };
    socketContext.socket.emit("deleteAnnouncement", body);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getCreatedBy = () => {
    const userId = announcement.created_by;

    const getUsersListener = (data) => {
      if (data.length > 0) {
        setCreatedBy(data[0]);
      } else {
        setCreatedBy([]);
      }
    };

    socketContext.socket.on("allUsersWithParams" + userId, getUsersListener);

    const args = { user_id: userId };
    socketContext.socket.emit("getAllUsersWithParams", args);

    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    return () => {
      socketContext.socket.off("allUsersWithParams" + userId, getUsersListener);
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const [descriptionImgUrl, setDescriptionImgUrl] = useState("");
  const [showDescriptionImgFull, setShowDescriptionImgFull] = useState(false);

  const imageClickListener = () => {
    let examTitles = document
      .getElementById("description" + announcement.announcement_id)
      .getElementsByTagName("img");
    document.addEventListener("click", function (e) {
      for (let i = 0; i < examTitles.length; i++) {
        if (examTitles[i].contains(e.target)) {
          if (e.target.tagName === "IMG") {
            descriptionImageClick(e.target);
          }
        }
      }
    });
  };

  const descriptionImageClick = (imageElement) => {
    setDescriptionImgUrl(imageElement.src);
    setShowDescriptionImgFull(true);
  };

  const closeImgOverlay = () => {
    setShowDescriptionImgFull(false);
  };

  const populateDepartmentNames = () => {
    try {
      const classNames = JSON.parse(announcement.department_names);
      return classNames.map((depName, index) => {
        return (
          <div key={"tag-item-depName" + index} depName="tag-item">
            {depName}
            {index !== classNames.length - 1 ? "," : " - "}
          </div>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  const populateClassesNames = () => {
    try {
      const classNames = JSON.parse(announcement.class_names);
      return classNames.map((className, index) => {
        return (
          <div key={"tag-item-className" + index} className="tag-item">
            {className}
            {index !== classNames.length - 1 ? "," : " - "}
          </div>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  const editAnnouncement = () => {
    setAnnouncementToEdit(announcement);
  };

  const isRunningInWebView = () => {
    return window.ReactNativeWebView !== undefined;
  };

  return (
    <motion.div
      className={
        "item " +
        (showDescriptionImgFull ? " open-image " : " ") +
        (announcement.announcement_id == -1 || deleted
          ? "loading-item red bottom"
          : "")
      }
    >
      {showDescriptionImgFull && (
        <FullSizeImg
          imgSrc={descriptionImgUrl}
          closeOverlay={closeImgOverlay}
        />
      )}
      <div className="created_by">
        {createdBy && (
          <img
            alt="created_by student professor"
            className="created_by__img"
            src={
              createdBy.profile_picture
                ? createdBy.profile_picture
                : "resources/student.png"
            }
          />
        )}
        {createdBy ? (
          <div className="created_by__info">
            <div className="name">
              <span>{createdBy.first_name}</span>
              <span>{createdBy.last_name}</span>
            </div>
            <div className="date afc">
              {formatDate(announcement.created_at)}

              {profile.user_type == "professor" ||
              profile.user_type == "admin" ? (
                <>
                  -
                  {(profile.user_type == "admin" ||
                    profile.user_type == "professor") &&
                  announcement.class_names
                    ? populateClassesNames()
                    : "'Ολα τα μαθήματα - "}
                  {(profile.user_type == "admin" ||
                    profile.user_type == "professor") &&
                  announcement.department_names
                    ? populateDepartmentNames()
                    : t("all_departments") + " - "}
                  {t("to")}
                  {announcement.for_professors
                    ? " " + t("professors") + " "
                    : ""}
                  {announcement.for_students ? " " + t("students") + " " : ""}
                  {announcement.for_parents ? " " + t("parents") + " " : ""}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="content">
        <div className="details">
          <div
            className="description editor-read-only"
            id={"description" + announcement.announcement_id}
          >
            <CKEditor
              editor={ClassicEditor}
              data={announcement.description}
              config={{
                toolbar: [],
                link: {
                  decorators: {
                    openInNewTab: {
                      mode: "automatic",
                      callback: () => !isRunningInWebView(), // Apply target="_blank" only if NOT in WebView
                      attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                  },
                },
              }}
              onReady={(editor) => {
                editor.editing.view.document.on(
                  "click",
                  (event, domEvent) => {
                    const linkElement = domEvent.target.closest("a");

                    if (linkElement) {
                      const url = linkElement.getAttribute("href");

                      if (isRunningInWebView() && window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            type: "OPEN_LINK",
                            data: url,
                          })
                        );

                        domEvent.preventDefault();
                      }
                    }
                  },
                  { priority: "high" }
                ); // Ensure this runs before CKEditor's default behavior
              }}
              disabled={true}
            />
          </div>
        </div>
        {profile.user_type == "admin" ||
        profile.user_id == announcement.created_by ? (
          <div className="item__delete">
            <div className="item__delete-init" ref={deleteInitRef}>
              <BiDotsHorizontal
                size={"25px"}
                color={"#c9c9c9"}
                style={iconStyle("transparent")}
                onClick={() => deleteClick()}
              />
            </div>
            {showDeleteOptions ? (
              <div className="item__delete-options" ref={deleteOptionsRef}>
                <span className="fc" onClick={() => deleteAnnouncement()}>
                  {t("delete")}
                </span>
                <span className="fc" onClick={() => editAnnouncement()}>
                  Επεξεργασία
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </motion.div>
  );
}

export default AnnouncementItem;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// IMPORTANT: Register components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ExamLineChart = ({ item, exams }) => {
  // Sort exams by their start_at date
  const sortedExams = [...exams].sort(
    (a, b) => new Date(a.start_at) - new Date(b.start_at)
  );

  // Labels for the x-axis (format date as needed)
  const labels = sortedExams.map((exam) =>
    new Date(exam.start_at).toLocaleDateString()
  );

  // Data for the y-axis (exam scores)
  const scores = sortedExams.map((exam) => exam.score);

  const hexToRgba = (hex, opacity) => {
    // Remove the '#' if it's included
    hex = hex.replace(/^#/, "");

    // Convert shorthand hex (e.g., #ccc) to full format (e.g., #cccccc)
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Parse r, g, b values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  // Chart.js data object
  const data = {
    labels,
    datasets: [
      {
        label: item.graph_title ? item.graph_title : "Γράφημα Διαγωνισμάτων",
        data: scores,
        fill: true, // Enables the fill effect
        borderColor: item.line_color ? item.line_color : "#6225e6",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return "rgba(98, 37, 230, 0.2)"; // Default color in case chartArea is not available
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, hexToRgba(item.backdrop || "#6225e6", 0.4)); // Start with item.backdrop at 40% opacity
          gradient.addColorStop(1, hexToRgba(item.backdrop || "#6225e6", 0));

          return gradient;
        },
        tension: 0.3, // Smoother curve
      },
    ],
  };

  // Chart.js options object (Chart.js 3/4 syntax)
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // This hides the legend
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Exam Date",
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: "Score",
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default ExamLineChart;

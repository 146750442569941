import React from "react";

const PrintEvaluation = ({
  item,
  evaluation = [],
  evaluationSettings = [],
}) => {
  return (
    <div className="print-evaluation" style={{ padding: "10px" }}>
      {evaluation.map((evalItem, index) => {
        // Get the corresponding settings object (if available)
        const setting = evaluationSettings[index] || {};
        return (
          <div
            key={index}
            className="evaluation-row"
            style={{
              display: "flex",
              alignItems: "stretch", // This makes sure both columns match the height of the taller one
              marginBottom: "10px",
              border: "1px solid #ccc",
            }}
          >
            <div
              className="evaluation-title"
              style={{
                width: "150px", // Fixed width for the title
                borderRight: "1px solid #ccc",
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              {setting.title || "No Title"}
            </div>
            <div
              className="evaluation-comments"
              style={{
                flex: 1,
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              {evalItem.general_comments}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrintEvaluation;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import "./calendar.scss";
import { SocketContext } from "../../app/socket";
import { useNavigate } from "react-router-dom";
import {
  BiX,
  BiArrowFromRight,
  BiArrowFromLeft,
  BiCarousel,
  BiTime,
  BiChevronLeft,
  BiChevronRight,
  BiSearch,
  BiAlarmExclamation,
  BiChevronDown,
  BiBook,
  BiBookContent,
  BiPhone,
} from "react-icons/bi";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import LoaderCard from "../LoaderCard/loaderCard";
import ConfigureEvent from "../ConfigureEvent/configureEvent";
import { EventProvider } from "../ConfigureEvent/eventContext";
import { useHomepageContext } from "../../pages/Homepage/homepageContext";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import EventsItem from "./events/eventsItem";
import { cookieUtils } from "../../utils/cookie-parser";
import ReadingRoom from "./readingRoom/readingRoom";
import GeneralTest from "./generalTest/generalTest";
import ParentContact from "./parentContact/parentContact";
import { t } from "i18next";

const days = ["Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ", "Κυρ"];
const months = [
  "Ιανουάριος",
  "Φεβρουάριος",
  "Μάρτιος",
  "Απριλίος",
  "Μάιος",
  "Ιούνιος",
  "Ιούλιος",
  "Άυγουστος",
  "Σεπτέμβριος",
  "Οκτώβριος",
  "Νοέμβριος",
  "Δεκέμβριος",
];

function Calendar() {
  const navigate = useNavigate();
  const { setModalOpen } = useHomepageContext();
  const dateRended = useRef(0);
  const searchRender = useRef(0);
  const typeRender = useRef(0);
  const profileRender = useRef(0);

  const [editIsOpen, setEditIsOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState();
  const [configureSlideEdit, setConfigureSlideEdit] = useState("general");

  const [gotType, setGotType] = useState(false);
  const [configureSlide, setConfigureSlide] = useState("type");

  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const generalValues = useSelector((state) => state.profile.generalValues);

  const [calendarDate, setCalendarDate] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchEvent, setSearchEvent] = useState("");
  const [eventType, setEventType] = useState("all");

  const [events, setEvents] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [gotEvents, setGotEvents] = useState(false);

  const [monthEvents, setMonthEvents] = useState([]);

  const [date, setDate] = useState(new Date());

  const [dismissalInitActive, setDismissalInitActive] = useState(true);

  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const selectedDateRef = useRef(0);

  // const [toggleTests, setToggleTests] = useState(false);

  const [toggleTests, setToggleTests] = useState(() => {
    const storedValue = cookieUtils.getCookie("toggleTests");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    const isRunningInWebView = () => {
      return window.ReactNativeWebView !== undefined;
    };
    if (!isRunningInWebView()) {
      cookieUtils.setCookie("toggleTests", JSON.stringify(toggleTests), 7);
    }
  }, [toggleTests]);

  useEffect(() => {
    setSelectedDate(calendarDate.toDate());
  }, [calendarDate]);

  useEffect(() => {
    if (
      profile &&
      profileRender.current !== 0 &&
      generalValues &&
      generalValues.length &&
      generalSettings &&
      generalSettings.length
    ) {
      setGotEvents(false);
      const cleanUpGetEvents = getEvents();
      return () => {
        cleanUpGetEvents();
      };
    }
    profileRender.current += 1;
  }, [profile, generalValues, generalSettings]);

  useEffect(() => {
    if (selectedDateRef.current >= 2) {
      console.log(" getting events here ");
      setGotEvents(false);
      const cleanUpGetEvents = getEvents();
      return () => {
        cleanUpGetEvents();
      };
    }
    selectedDateRef.current += 1;
  }, [selectedDate]);

  useEffect(() => {
    if (dateRended.current !== 0) {
      return getAnnouncements();
    }
    dateRended.current += 1;
  }, [selectedDate, generalValues]);

  useEffect(() => {
    const cleanUpGetMonthEvents = getMonthEvents();
    return () => {
      cleanUpGetMonthEvents();
    };
  }, [selectedDate]);

  useEffect(() => {
    if (searchEvent !== undefined && searchRender.current !== 0) {
      setGotEvents(false);
      return getEvents();
    }
    searchRender.current += 1;
  }, [searchEvent]);

  // useEffect(() => {
  //   if (eventType !== undefined && typeRender.current !== 0) {
  //     return getEvents();
  //   }
  //   typeRender.current += 1;
  // }, [eventType]);

  const socketContext = useContext(SocketContext);

  const getAnnouncements = () => {
    if (selectedDate) {
      const uniqueId = "calendar";
      let args = {
        day: selectedDate.getDate(),
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
        full_date: selectedDate,
        unique_id: uniqueId,
      };

      const getAnnouncementsListener = (data) => {
        const temp = data;
        temp.map((an) => {
          an.type = "announcement";
        });
        setAnnouncements(temp);
      };

      const refreshAnnouncements = () => {
        socketContext.socket.emit("getAnnouncements", args);
      };

      socketContext.socket.on(
        "announcements" + uniqueId,
        getAnnouncementsListener
      );
      socketContext.socket.emit("getAnnouncements", args);
      socketContext.socket.on("refreshAnnouncements", refreshAnnouncements);

      return () => {
        socketContext.socket.off("getAnnouncements", getAnnouncementsListener);
        socketContext.socket.off(
          "announcements" + uniqueId,
          getAnnouncementsListener
        );
        socketContext.socket.off("refreshAnnouncements", refreshAnnouncements);
      };
    }
  };

  const getMonthEvents = () => {
    if (selectedDate) {
      let args = {
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      };

      const getMonthEventsListener = (data) => {
        setMonthEvents(data);
      };

      const refreshMonthEventsListener = () => {
        socketContext.socket.emit("getMonthEvents", args);
      };

      socketContext.socket.on("monthEvents", getMonthEventsListener);
      socketContext.socket.emit("getMonthEvents", args);
      socketContext.socket.on("refreshEvents", refreshMonthEventsListener);

      return () => {
        socketContext.socket.off("getMonthEvents", getMonthEventsListener);
        socketContext.socket.off("monthEvents", getMonthEventsListener);
        socketContext.socket.off("refreshEvents", refreshMonthEventsListener);
      };
    }
  };

  const getEvents = () => {
    let isMounted = true;
    if (selectedDate) {
      const uniqueId = "homepage";
      let args = {
        day: selectedDate.getDate(),
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
        search: searchEvent,
        type: eventType,
        full_date: selectedDate,
        uniqueId: uniqueId,
        prof_all_events: getGeneralSettingValue(
          generalSettings,
          "professors_view_all_events"
        ),
        get_until_date:
          (profile.user_type == "parent" || profile.user_type == "student") &&
          getGeneralSettingValue(generalSettings, "exams_visible_until_date"),
        until_date:
          generalValues &&
          generalValues.length &&
          generalValues.find((gen) => gen.name == "exams_visible_until")
            ? generalValues.find((gen) => gen.name == "exams_visible_until")
                .value
            : new Date(),
      };
      if (profile.user_type === "professor") {
        args.user_id = profile.user_id;
      }

      const getEventsListener = (data) => {
        if (isMounted) {
          setGotEvents(true);
          const eventsTemp = data.map((event) => {
            const classroomNames = event.classroom_names
              ? event.classroom_names.split(",")
              : [];
            const departmentNames =
              event.department_id == "[-1]"
                ? ["Όλα"]
                : event.department_names
                ? event.department_names.split(",")
                : [];
            const professorNames = event.professor_names
              ? event.professor_names
                  .replace(/\s+/g, " ") // Replace multiple spaces with a single space
                  .split(/\s*,\s*/) // Split by commas with optional surrounding spaces
                  .map((name) => name.trim()) // Trim whitespace from each name
              : [];
            return {
              ...event,
              classroom_names: classroomNames,
              department_names: departmentNames,
              professor_names: professorNames,
            };
          });
          setEvents(eventsTemp);
        }
      };

      const refreshEventsListener = () => {
        socketContext.socket.emit("getEvents", args);
      };

      socketContext.socket.on("events" + uniqueId, getEventsListener);

      socketContext.socket.emit("getEvents", args);

      socketContext.socket.on("refreshEvents", refreshEventsListener);

      return () => {
        isMounted = false;
        socketContext.socket.off("getEvents", getEventsListener);
        socketContext.socket.off("events" + uniqueId, getEventsListener);
        socketContext.socket.off("refreshEvents", refreshEventsListener);
      };
    }
  };

  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [overflow, setOverflow] = useState("hidden");

  useEffect(() => {
    setModalOpen(showCreateEvent);
    if (showCreateEvent) {
      setOverflow("hidden");
      document.body.style.overflowY = "hidden";
    } else {
      setOverflow("auto");
      document.body.style.overflowY = "auto";
      setGotType(false);
    }
  }, [showCreateEvent]);

  useEffect(() => {
    if (editIsOpen) {
      setConfigureSlide("general");
      setOverflow("hidden");
      document.body.style.overflowY = "hidden";
    } else {
      setOverflow("auto");
      document.body.style.overflowY = "auto";
    }
  }, [editIsOpen]);

  const openCreateEventModal = () => {
    if (!showCreateEvent) {
      setShowCreateEvent(true);
      setOverflow("hidden");
      document.body.style.overflowY = "hidden";
    }
  };

  const openCalendarBig = () => {
    navigate("/calendar");
  };

  const sortEvents = () => {
    const sortedEvents = events
      .map((event) => ({
        ...event,
        start_at: new Date(event.start_at),
        finish_at: new Date(event.finish_at),
      }))
      .sort((a, b) => {
        // Sort old events at the top
        if (a.old_event && !b.old_event) return -1;
        if (!a.old_event && b.old_event) return 1;

        // Ensure parent-events are sorted first
        if (a.type === "parent-event" && b.type !== "parent-event") return -1;
        if (a.type !== "parent-event" && b.type === "parent-event") return 1;

        // Sort online-exam events after parent-events
        if (a.type === "online-exam" && b.type !== "online-exam") return -1;
        if (a.type !== "online-exam" && b.type === "online-exam") return 1;

        // Sort by time (start_at and finish_at)
        if (a.start_at.getHours() !== b.start_at.getHours()) {
          return a.start_at.getHours() - b.start_at.getHours();
        }
        if (a.start_at.getMinutes() !== b.start_at.getMinutes()) {
          return a.start_at.getMinutes() - b.start_at.getMinutes();
        }
        return a.finish_at.getTime() - b.finish_at.getTime();
      });

    const withHeaders = [];
    let previousHour = null;
    let addedOldEventHeader = false; // Track if the old_event header has been added

    sortedEvents.forEach((event) => {
      const currentHour =
        new Date(event.start_at).getHours() +
        new Date(event.start_at).getMinutes();

      // Add a single header for old events
      if (event.old_event && !addedOldEventHeader) {
        withHeaders.push({
          event_id: -30,
          type: "old_event",
          start_at: event.start_at,
          finish_at: event.finish_at,
        });
        addedOldEventHeader = true; // Ensure header is only added once
      }

      // Add header for online-exams and parent-events
      if (event.type === "online-exam") {
        withHeaders.push({
          event_id: -20,
          type: "online-exam-header",
          start_at: event.start_at,
          finish_at: event.finish_at,
        });
      }

      if (event.type === "parent-event") {
        withHeaders.push({
          event_id: -20,
          type: "parent-event-header",
          start_at: event.start_at,
          finish_at: event.finish_at,
        });
      }

      // Add hour-based headers for non-online-exam, non-old-event events
      if (
        !event.old_event &&
        event.type !== "online-exam" &&
        event.type !== "parent-event" &&
        currentHour !== previousHour
      ) {
        withHeaders.push({
          event_id: -10,
          type: "header",
          start_at: event.start_at,
        });
        previousHour = currentHour;
      }

      withHeaders.push(event);
    });

    return withHeaders;
  };

  const [showIframe, setShowIframe] = useState("");

  const populateAnnouncements = () => {
    return announcements.map((announcement, index) => {
      return (
        <EventsItem
          key={
            "event__item announcement " +
            announcement.announcement_id +
            "-" +
            index
          }
          event={announcement}
          setEditIsOpen={setEditIsOpen}
          setEventToEdit={setEventToEdit}
          selectedDate={selectedDate}
          dismissalInitActive={dismissalInitActive}
        ></EventsItem>
      );
    });
  };

  const populateEvents = () => {
    const sortedEvents = sortEvents();

    let oldEventItems = []; // Array to hold old_event items
    let nonOldEventItems = sortedEvents.filter((event) => !event.old_event); // Filter out old events

    return nonOldEventItems.map((event, index) => {
      // Render the old_event header and wrap events inside events__old-wrapper
      if (event.event_id === -30) {
        // Collect all the old events inside the wrapper
        oldEventItems = sortedEvents
          .filter((ev) => ev.old_event)
          .map((oldEvent, oldIndex) => (
            <EventsItem
              key={"old_event__item " + oldEvent.event_id + "-" + oldIndex}
              event={oldEvent}
              setEditIsOpen={setEditIsOpen}
              setEventToEdit={setEventToEdit}
              selectedDate={selectedDate}
              dismissalInitActive={dismissalInitActive}
            />
          ));

        return (
          <div className="events__old">
            <div
              className="events__line old"
              onClick={() => setToggleTests(!toggleTests)}
            >
              <div className="line"></div>
              <div className="events__line-label">
                <BiTime
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <span>Τεστάκια</span>
              </div>
              <div className="events__line arrow">
                <BiChevronDown
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </div>
            </div>
            <div
              key={"events__old-wrapper" + index}
              className={"events__old-wrapper " + (toggleTests ? " close" : "")}
            >
              {oldEventItems}
            </div>
          </div>
        );
      }

      // Render time-based headers
      if (event.event_id === -10) {
        return (
          <div key={"events__line" + index} className="events__line">
            <div className="line"></div>
            <div className="events__line-label">
              <BiTime
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>
                {new Date(event.start_at).getHours()} :{" "}
                {`0${new Date(event.start_at).getMinutes()}`.slice(-2)}
              </span>
            </div>
          </div>
        );
      }

      // Render online-exam header
      else if (event.event_id === -20) {
        const startAt = new Date(event.start_at);
        const finishAt = new Date(event.finish_at);
        const isSameDay =
          startAt.getDate() === finishAt.getDate() &&
          startAt.getMonth() === finishAt.getMonth() &&
          startAt.getFullYear() === finishAt.getFullYear();
        const today = new Date();
        const isStartingDay =
          today.getDate() === startAt.getDate() &&
          today.getMonth() === startAt.getMonth() &&
          today.getFullYear() === startAt.getFullYear();
        const isEndingDay =
          today.getDate() === finishAt.getDate() &&
          today.getMonth() === finishAt.getMonth() &&
          today.getFullYear() === finishAt.getFullYear();

        let label = "Ολοήμερο";

        if (event.type == "parent-event-header") {
          label = "Ενημέρωση Γονέων";
        } else {
          if (isStartingDay && today < startAt) {
            label = `Ολοήμερο - Ξεκινάει στις ${startAt.getHours()} : ${`0${startAt.getMinutes()}`.slice(
              -2
            )}`;
          } else if (isEndingDay && today >= finishAt) {
            label = `Ολοήμερο - Λήγει στις ${finishAt.getHours()} : ${`0${finishAt.getMinutes()}`.slice(
              -2
            )}`;
          } else if (isSameDay) {
            label = `${startAt.getHours()} : ${`0${startAt.getMinutes()}`.slice(
              -2
            )} - ${finishAt.getHours()} : ${`0${finishAt.getMinutes()}`.slice(
              -2
            )}`;
          }
        }

        return (
          <div key={"online-exam__line" + index} className="events__line">
            <div className="line"></div>
            <div className="events__line-label">
              <BiTime
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>{label}</span>
            </div>
          </div>
        );
      }

      // Render normal event items
      else {
        return (
          <EventsItem
            key={"event__item " + event.event_id + "-" + index}
            event={event}
            setEditIsOpen={setEditIsOpen}
            setEventToEdit={setEventToEdit}
            selectedDate={selectedDate}
            dismissalInitActive={dismissalInitActive}
            showIframe={showIframe}
            setShowIframe={setShowIframe}
          />
        );
      }
    });
  };

  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  const adjustedFirstDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
  const daysInMonth =
    32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();

  let dates = Array(adjustedFirstDay)
    .fill(null)
    .concat(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  while (dates.length % 7 !== 0) dates.push(null);

  const selectDate = (day) => {
    if (day == new Date(selectedDate).getDate()) {
      return;
    }
    if (day) {
      setSelectedDate(
        day ? new Date(date.getFullYear(), date.getMonth(), day, 15) : null
      );
    }
  };

  const changeMonth = (offset) => {
    setSelectedDate(new Date(date.getFullYear(), date.getMonth() + offset, 1));
    setDate(new Date(date.getFullYear(), date.getMonth() + offset, 1));
  };

  return (
    <div className="calendar-full">
      {showIframe ? (
        <div className="event-item__iframe">
          <div
            className="event-item__iframe-close"
            onClick={() => setShowIframe(null)}
          >
            <span>Τερματισμός κλήσης</span>
          </div>
          <iframe allow="camera; microphone" src={showIframe} />
        </div>
      ) : (
        ""
      )}
      <div className="calendar-full__header">
        <div className="calendar-full__header-title fc">{t("program")}</div>
        <div className="filters">
          <div className="filters__list">
            <div className="filters__list-item">
              <span>{t("filters")}</span>
            </div>
          </div>
          <div
            className={"filters__search " + (searchOpen ? "open" : "closed")}
            onClick={() => {
              if (!searchOpen) {
                setSearchOpen(true);
              }
            }}
          >
            <input
              className="input"
              placeholder="Αναζητήστε Εξέταση"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchOpen ? (
              <BiX
                className="search-close"
                onClick={() => setSearchOpen(false)}
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            ) : (
              <BiSearch
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="calendar-full__wrapper">
        <div className="calendar-full__left">
          <div className="calendar">
            <h2 className="calendar__title">
              {months[date.getMonth()]} {date.getFullYear()}
            </h2>
            <div className="calendar__controls prev">
              <BiChevronLeft
                size={"35px"}
                color={"#ccc"}
                style={iconStyle("#f2f2f2")}
                onClick={() => changeMonth(-1)}
              />
            </div>
            <div className="calendar__controls next">
              <BiChevronRight
                size={"35px"}
                color={"#ccc"}
                style={iconStyle("#f2f2f2")}
                onClick={() => changeMonth(1)}
              />
            </div>
            <table className="calendar__table">
              <thead>
                <tr>
                  {days.map((day, index) => (
                    <th key={"day" + index}>{day}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dates
                  .reduce((rows, day, index) => {
                    if (index % 7 === 0) rows.push([]);
                    rows[rows.length - 1].push(day);
                    return rows;
                  }, [])
                  .map((week, index) => (
                    <tr className="calendar__row" key={index}>
                      {week.map((day, index) => (
                        <td
                          key={"calendar-day" + day + "sel" + selectDate}
                          className={
                            "calendar__row-day " +
                            (!day ? " empty " : "") +
                            (day === selectedDate?.getDate() ? "active " : "") +
                            (day === new Date().getDate() &&
                            selectedDate.getMonth() === new Date().getMonth()
                              ? " current "
                              : " ") +
                            (monthEvents &&
                            day >= 1 &&
                            day &&
                            monthEvents[day - 1] &&
                            monthEvents[day - 1].Events !== "No events"
                              ? " " + monthEvents[day - 1].Events
                              : "")
                          }
                          onClick={() => selectDate(day)}
                          key={index}
                        >
                          {day}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {profile.user_type == "admin" &&
          getGeneralSettingValue(generalSettings, "include_timetables") ? (
            <button
              className="cta cta-shadow w-100 mt-4 manage-calendar"
              onClick={() => openCalendarBig()}
            >
              {window.location.hostname.includes("praxis") ||
              window.location.hostname.includes("localhost")
                ? "Εναλλαγή Αιθουσών"
                : "Αυτόματη Δημιουργία"}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="calendar-full__right">
          <div className="events">
            {showCreateEvent || editIsOpen ? (
              <div
                onClick={() => {
                  setShowCreateEvent(false);
                  setConfigureSlide("type");
                  setEditIsOpen(false);
                  setConfigureSlideEdit("general");
                }}
                className={
                  "modal-background show " + (showCreateEvent ? "show" : "")
                }
              ></div>
            ) : (
              ""
            )}
            {editIsOpen ? (
              <div
                className={
                  "events__item-create got-type " +
                  (editIsOpen ? " is-open" : "") +
                  " " +
                  (configureSlideEdit == "type"
                    ? " general"
                    : configureSlideEdit)
                }
              >
                <div
                  className="events__item-create-close"
                  onClick={() => {
                    setEditIsOpen(false);
                    setConfigureSlideEdit("general");
                  }}
                >
                  <BiX
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                </div>
                <EventProvider
                  title={eventToEdit.title}
                  type={eventToEdit.type}
                  classId={eventToEdit.class_id}
                  departments={eventToEdit.department_id}
                  professors={eventToEdit.professors}
                  startDate={eventToEdit.start_at}
                  endDate={eventToEdit.finish_at}
                  id={eventToEdit.event_id}
                  classroom={eventToEdit.classroom_id}
                  repeatType={eventToEdit.repeat_type}
                  detailedRank={eventToEdit.detailed_rank}
                  maxRank={eventToEdit.max_rank}
                  eventOnlineGrades={eventToEdit.exam_details}
                  examId={eventToEdit.exam_id}
                  anId={
                    eventToEdit.announcement_id
                      ? eventToEdit.announcement_id
                      : ""
                  }
                  anTitle={
                    eventToEdit.description ? eventToEdit.description : ""
                  }
                  anForParents={
                    eventToEdit.for_parents ? eventToEdit.for_parents : ""
                  }
                  anForProfessors={
                    eventToEdit.for_professors ? eventToEdit.for_professors : ""
                  }
                  anForStudents={
                    eventToEdit.for_students ? eventToEdit.for_students : ""
                  }
                >
                  <ConfigureEvent
                    isEdit={true}
                    setConfigureSlide={setConfigureSlideEdit}
                    configureSlide={configureSlideEdit}
                    setShowCreateEvent={setEditIsOpen}
                  />
                </EventProvider>
              </div>
            ) : (
              ""
            )}
            {profile.user_type !== "student" &&
              profile.user_type !== "parent" &&
              (!getGeneralSettingValue(
                generalSettings,
                "professors_not_create"
              ) ||
                profile.user_type != "professor") && (
                <motion.div
                  layout
                  className={
                    "events__item-create " +
                    (showCreateEvent ? " is-open" : "") +
                    (gotType ? " got-type " : "") +
                    " " +
                    configureSlide
                  }
                  onClick={() => {
                    openCreateEventModal();
                  }}
                  initial={"closed"}
                  animate={showCreateEvent ? "open" : "closed"}
                  exit={"closed"}
                  whileHover={
                    !showCreateEvent
                      ? { scale: 1.03, transition: { duration: 0.25 } }
                      : {}
                  }
                >
                  {!showCreateEvent && (
                    <motion.div
                      className="events__item-create-info"
                      layout
                      // layout="position"
                    >
                      <BiCarousel
                        size={"30px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                      />
                      <span className="fc">{t("configure_event_titel")}</span>
                    </motion.div>
                  )}
                  {showCreateEvent && (
                    <motion.div
                      key={"createContentModal"}
                      className={"events__item-create-motion " + configureSlide}
                      style={{ overflowY: overflow }}
                    >
                      <div
                        className="events__item-create-close"
                        onClick={() => {
                          setShowCreateEvent(false);
                          setConfigureSlide("type");
                        }}
                      >
                        <BiX
                          size={"35px"}
                          color={"#cccccc"}
                          style={iconStyle("#f2f2f2")}
                        />
                      </div>
                      <EventProvider>
                        <ConfigureEvent
                          setConfigureSlide={setConfigureSlide}
                          setShowCreateEvent={setShowCreateEvent}
                          setGotType={setGotType}
                          configureSlide={configureSlide}
                          events={events}
                          setEvents={setEvents}
                          showLecture={true}
                          selectedDate={selectedDate}
                        />
                      </EventProvider>
                    </motion.div>
                  )}
                </motion.div>
              )}
            {getGeneralSettingValue(generalSettings, "reading_room") &&
            profile.user_type == "admin" ? (
              <div className="events__line">
                <div className="events__line-label">
                  <BiBook
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="fc">{t("studying_room")}</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {getGeneralSettingValue(generalSettings, "reading_room") &&
            profile.user_type == "admin" ? (
              <ReadingRoom
                key={"event__item reading_room " + -10 + "-"}
                event={{
                  event_id: -999,
                  class_id: -1,
                  start_at: new Date(),
                  finish_at: new Date(),
                  title: "Αναγνωστήριο",
                  type: "reading_room",
                }}
                setEditIsOpen={setEditIsOpen}
                setEventToEdit={setEventToEdit}
                selectedDate={selectedDate}
                dismissalInitActive={dismissalInitActive}
              ></ReadingRoom>
            ) : (
              ""
            )}
            {(window.location.href.includes("localhost") ||
              (window.location.href.includes("simmetria") &&
                profile.user_type === "admin")) &&
            selectedDate.getDay() === 0 ? (
              <div className="events__line">
                <div className="events__line-label">
                  <BiPhone
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="fc">{t("contact_with_parent")}</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {(window.location.href.includes("localhost") ||
              window.location.href.includes("simmetria")) &&
            profile.user_type === "admin" &&
            selectedDate.getDay() == 0 ? (
              <ParentContact
                key={"event__item parent-contact " + -10 + "-" + selectedDate}
                selectedDate={selectedDate}
              />
            ) : (
              ""
            )}

            {getGeneralSettingValue(generalSettings, "general_test") &&
            (!getGeneralSettingValue(
              generalSettings,
              "professors_not_create"
            ) ||
              profile.user_type != "professor") ? (
              <div className="events__line">
                <div className="events__line-label">
                  <BiBookContent
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="fc">{t("daily_test")}</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {getGeneralSettingValue(generalSettings, "general_test") &&
            (!getGeneralSettingValue(
              generalSettings,
              "professors_not_create"
            ) ||
              profile.user_type != "professor") ? (
              <GeneralTest
                key={"event__item general-test " + -10 + "-"}
                selectedDate={selectedDate}
              />
            ) : (
              ""
            )}
            {getGeneralSettingValue(generalSettings, "announcement_calendar") &&
            announcements &&
            announcements.length ? (
              <div className="events__line">
                <div className="events__line-label">
                  <BiAlarmExclamation
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span className="fc">Ανακοινώσεις</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {getGeneralSettingValue(generalSettings, "announcement_calendar")
              ? populateAnnouncements()
              : ""}
            {gotEvents && populateEvents()}
            {!gotEvents && <LoaderCard cardSum={3} width={100} size="medium" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Calendar);

import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./template-preview.scss";

const ResponsiveGridLayout = WidthProvider(Responsive);

const TemplatePreview = ({ templateData }) => {
  // If `templateData` is your layout array
  if (!templateData || !Array.isArray(templateData)) {
    return <div>No layout to preview</div>;
  }

  // Original editor width
  const ORIGINAL_WIDTH = 700;
  // Desired preview width in px
  const PREVIEW_WIDTH = 200;
  // Compute scale factor
  const scaleFactor = PREVIEW_WIDTH / ORIGINAL_WIDTH;

  // React-grid-layout expects one layout array repeated for each breakpoint
  const layouts = {
    lg: templateData,
    md: templateData,
    sm: templateData,
    xs: templateData,
    xxs: templateData,
  };

  return (
    <div
      className="template-preview-outer"
      style={{
        width: PREVIEW_WIDTH,
        height: "300px",
        overflow: "hidden", // or "auto" if you want scrollbars
        position: "relative",
        border: "1px solid #ccc",
      }}
    >
      {/* The inner container will maintain the original 700px width,
          then we apply transform: scale(...) to shrink it */}
      <div
        className="template-preview-scale-container"
        style={{
          width: ORIGINAL_WIDTH,
          // The height can be "auto" or set to some known height
          // but for the grid layout, it will expand as needed.
          transform: `scale(${scaleFactor})`,
          transformOrigin: "top left",
        }}
      >
        <ResponsiveGridLayout
          className="preview-layout"
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          // Keep the original 700 width so layout positions match your editor
          width={ORIGINAL_WIDTH}
          rowHeight={5}
          isDroppable={false}
          isDraggable={false}
          isResizable={false}
          compactType="vertical"
        >
          {templateData.map((item) => (
            <div
              key={item.i}
              style={item.style || {}}
              className="preview-grid-item"
            >
              {/* TEXT */}
              {item.type === "text" && (
                <div
                  className="preview-text"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              )}

              {/* IMAGE */}
              {item.type === "image" && (
                <img
                  className="preview-image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: item.style?.objectFit || "cover",
                  }}
                  src="https://t4.ftcdn.net/jpg/07/40/34/41/360_F_740344153_IQT8C4zfJj81LWvYcBfCpQXaO1lNeIXW.jpg"
                  alt="Preview"
                />
              )}

              {/* SPACER */}
              {item.type === "spacer" && <div className="preview-spacer" />}

              {/* LINE */}
              {item.type === "line" && <div className="preview-line" />}

              {/* EXAM STATS */}
              {item.type === "exam-stats" && (
                <div className="preview-exam-stats">
                  <table style={{ tableLayout: "fixed", width: "100%" }}>
                    <thead>
                      <tr>
                        {item.tableData.columns
                          .filter((col) => col.shown !== false)
                          .map((col, idx) => (
                            <th
                              key={idx}
                              style={{ width: (col.width || 25) + "%" }}
                            >
                              {col.label}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {item.tableData.columns
                          .filter((col) => col.shown !== false)
                          .map((col, idx) => (
                            <td
                              key={idx}
                              style={{ width: (col.width || 25) + "%" }}
                            >
                              Placeholder
                            </td>
                          ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default TemplatePreview;

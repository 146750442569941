import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BsAlignMiddle, BsArrowsMove } from "react-icons/bs";
import { iconStyle } from "../../../utils/generalUtils";

function normalizeWidths(columns) {
  const shownCols = columns.filter((c) => c.shown);
  if (shownCols.length === 0) return columns; // nothing to do

  const EPSILON = 0.001;
  let sum = shownCols.reduce((acc, c) => acc + c.width, 0);

  // Quick early-exit if already near 100
  if (Math.abs(sum - 100) < EPSILON) {
    return columns;
  }

  const clampedSet = new Set(); // store column 'type' that is already clamped
  let changed = true;

  // We'll loop until no more changes or we've balanced to ~100
  for (let iteration = 0; iteration < 20 && changed; iteration++) {
    changed = false;
    sum = shownCols.reduce((acc, c) => acc + c.width, 0);
    if (Math.abs(sum - 100) < EPSILON) break;

    const ratio = 100 / sum;

    // Scale each non-clamped column
    for (let col of shownCols) {
      if (clampedSet.has(col.type)) continue; // skip clamped
      let newWidth = col.width * ratio;

      if (newWidth > 70) {
        newWidth = 70;
        clampedSet.add(col.type);
      } else if (newWidth < 5) {
        newWidth = 5;
        clampedSet.add(col.type);
      }

      if (Math.abs(newWidth - col.width) > EPSILON) {
        col.width = newWidth;
        changed = true;
      }
    }
  }

  return columns;
}

const InspectorExamStats = ({ item, onUpdateItem }) => {
  const columns = item.tableData?.columns || [];

  useEffect(() => {
    let needsUpdate = false;
    const updatedCols = columns.map((col) => {
      let changed = false;
      const clone = { ...col };
      if (typeof clone.shown !== "boolean") {
        clone.shown = true; // default
        changed = true;
      }
      if (!clone.width) {
        clone.width = 25; // default
        changed = true;
      }
      if (changed) needsUpdate = true;
      return clone;
    });
    if (needsUpdate) {
      onUpdateItem({
        ...item,
        tableData: { ...item.tableData, columns: updatedCols },
      });
    }
    // eslint-disable-next-line
  }, []);

  // Handle toggling show/hide
  const handleShowToggle = (colIndex) => {
    const updatedCols = columns.map((col, idx) => {
      if (idx !== colIndex) return col;
      return { ...col, shown: !col.shown };
    });

    // After toggling, we want to re-normalize widths (only if we still have at least 1 shown column)
    const shownCount = updatedCols.filter((c) => c.shown).length;
    if (shownCount > 0) {
      normalizeWidths(updatedCols);
    }

    // Save the updated columns
    reorderAndSave(updatedCols);
  };

  // Handle slider change
  const handleWidthChange = (colIndex, newVal) => {
    const updatedCols = columns.map((col, idx) => {
      if (idx !== colIndex) return col;
      return { ...col, width: parseFloat(newVal) };
    });

    // After changing one column's width, re-normalize
    normalizeWidths(updatedCols);

    reorderAndSave(updatedCols);
  };

  /**
   * Reorders columns so that shown columns come first, hidden columns last,
   * preserving the internal order among shown and among hidden.
   */
  const partitionShownHidden = (arr) => {
    const shown = arr.filter((c) => c.shown);
    const hidden = arr.filter((c) => !c.shown);
    return [...shown, ...hidden];
  };

  // Save after reordering shown/hidden
  const reorderAndSave = (newCols) => {
    const partitioned = partitionShownHidden(newCols);
    onUpdateItem({
      ...item,
      tableData: {
        ...item.tableData,
        columns: partitioned,
      },
    });
  };

  // Drag & drop reordering
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    // Reorder in array
    const reordered = Array.from(columns);
    const [removed] = reordered.splice(sourceIndex, 1);
    reordered.splice(destIndex, 0, removed);

    // Then forcibly partition shown/hidden
    const partitioned = partitionShownHidden(reordered);

    // Save
    onUpdateItem({
      ...item,
      tableData: { ...item.tableData, columns: partitioned },
    });
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns-droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {columns.map((col, index) => (
                <Draggable key={col.type} draggableId={col.type} index={index}>
                  {(draggableProvided) => (
                    <div
                      className="inspector-item__exam-stats-draggable"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "6px",
                        padding: "8px",
                        ...draggableProvided.draggableProps.style,
                      }}
                    >
                      {/* Row for the handle, label, and checkbox */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Draggable handle icon */}
                        <div
                          {...draggableProvided.dragHandleProps}
                          style={{
                            cursor: "grab",
                            marginRight: "8px",
                          }}
                        >
                          <BsArrowsMove
                            size={24}
                            color={"#cccccc"}
                            style={iconStyle("transparent")}
                          />
                        </div>

                        <span className="item-label">{col.label}</span>

                        {/* Show/hide checkbox */}
                        <input
                          type="checkbox"
                          checked={col.shown}
                          onChange={() => handleShowToggle(index)}
                        />
                      </div>

                      {/* Width slider (only if shown) */}
                      {col.shown && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginTop: "8px",
                          }}
                        >
                          <span
                            style={{
                              width: "120px",
                              fontSize: "0.9em",
                            }}
                          >
                            Μέγεθος: {col.width.toFixed(1)}%
                          </span>
                          <input
                            type="range"
                            min={5}
                            max={70}
                            step={0.5}
                            value={col.width}
                            style={{ flex: 1 }}
                            onChange={(e) =>
                              handleWidthChange(index, e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default InspectorExamStats;

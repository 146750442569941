import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPencil, BiPlus, BiPrinter, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./print-total-users.scss";
import { useReactToPrint } from "react-to-print";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ClassSelector from "../../../components/ClassSelector/classSelector";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PrintTotalUsers({ isExpanded, setIsExpanded, students }) {
  const htmlRef = useRef(null);
  const currentDate = new Date();
  const oneMonthAhead = new Date();
  oneMonthAhead.setMonth(currentDate.getMonth() + 1);
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const schoolLogo = useSelector((state) => state.profile.schoolLogo);

  const [classes, setClasses] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([-1]);
  const [selectedClasses, setSelectedClasses] = useState([-1]);

  const [grades, setGrades] = useState([]);

  const [usersInGrades, setUsersInGrades] = useState([]);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    const cleanUpGetGrades = getGrades();
    return () => {
      cleanUpGetGrades();
    };
  }, []);

  const getGrades = () => {
    let args = { order_by_id: true };
    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);

    const refreshGradesListener = () => {
      socketContext.socket.emit("getGrades", args);
    };
    socketContext.socket.on("refreshGrades", refreshGradesListener);

    return () => {
      socketContext.socket.off("getGrades", getGradesListener);
      socketContext.socket.off("grades", getGradesListener);
      socketContext.socket.off("refreshGrades", refreshGradesListener);
    };
  };

  // useEffect(() => {
  //   const cleanUpGetUsersInGrades = getUsersInGrades();
  //   return () => {
  //     cleanUpGetUsersInGrades();
  //   };
  // }, []);

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const getUsersInGrades = () => {
    let args = { grades: selectedGrades, classes: selectedClasses };

    const getUsersInGradesListener = (data) => {
      setUsersInGrades(data);
    };

    const refreshUserGradesListener = () => {
      socketContext.socket.emit("getUsersInGrades", args);
    };

    socketContext.socket.on("usersInGrades", getUsersInGradesListener);
    socketContext.socket.emit("getUsersInGrades", args);
    socketContext.socket.on("refreshUsersInGrades", refreshUserGradesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getUsersInGrades", getUsersInGradesListener);
      socketContext.socket.off("usersInGrades", getUsersInGradesListener);
      socketContext.socket.off(
        "refreshUsersInGrades",
        refreshUserGradesListener
      );
    };
  };

  const populateUsers = () => {
    return usersInGrades.map((user) => {
      let phone1 = user.parent_personal_phone1;
      if (!phone1 || phone1 == undefined) {
        phone1 = "";
      }
      let phone2 = user.parent_personal_phone2;
      if (!phone2 || phone2 == undefined) {
        phone2 = "";
      }

      let parent1 = user.parent_first_name1 + " " + user.parent_last_name1;
      if (!user.parent_first_name1 || user.parent_first_name1 == undefined) {
        parent1 = "";
      }
      let parent2 = user.parent_first_name2 + " " + user.parent_last_name2;
      if (!user.parent_first_name2 || user.parent_first_name2 == undefined) {
        parent2 = "";
      }
      return `<tr>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${user.first_name} ${user.last_name}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${parent1}</td>
      <td width="20%" style="padding: 10px; word-break: break-word;  text-align: right; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${phone1}</td>
      <td width="20%" style="padding: 10px; word-break: break-word; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${parent2}</td>
      <td width="20%" style="padding: 10px; word-break: break-word;  text-align: right; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">${phone2}</td>
    </tr>`;
    });
  };

  const populateGrades = () => {
    return grades
      .filter((grade) => selectedGrades.includes(grade.grade_id))
      .map((gradeItem, index) => {
        let gradeName = gradeItem.grade_name + " ";
        if (
          grades.filter((grade) => selectedGrades.includes(grade.grade_id))
            .length <
          index - 1
        ) {
          gradeName += ` , `;
        }
        return `<span>${gradeName}</span>`;
      });
  };

  let htmlTemplate = `<!DOCTYPE html>
  <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&subset=greek" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" type="text/css" />
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        max-width: 100%; /* Prevent body overflow */
        width: 100%;
        font-family: 'Montserrat', sans-serif;
      }
  
      .nl-container {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 0;
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
      }
  
      /* Header styling */
      .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
  
      .header-container img {
        max-width: 300px;
        max-height: 200px;
        margin-bottom: 20px;
      }
  
      .header-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      .header-period {
        font-size: 16px;
        color: #666;
      }
  
      /* Student image and info */
      .student-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
      }
  
      .student-wrapper img {
        width: 140px !important;
        height: 140px !important;
        object-fit: cover;
        border-radius: 50%;
      }
  
      .student-info {
        font-size: 18px;
        color: #7747FF;
      }
  
      /* Tables */
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
      }
  
      th, td {
        padding: 10px;
        text-align: left;
      }
  
      thead {
        background-color: #f2f2f2;
      }
  
      /* Remove unwanted dots */
      td:empty::before {
        content: none !important;
      }
  
      /* Print styles */
      @media print {
        /* Force same weight across different subsets */
          body {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400; /* You can experiment with other weights */
          }
  
          :lang(el) {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400; /* Match the weight used for other characters */
          }

          .section-print {
            page-break-before: always;
          }
  
        body, .nl-container {
          margin: 0;
          padding: 0;
          width: 100%;
          max-width: 900px;
          overflow: visible;
        }
  
        .nl-container {
          width: 100% !important;
          max-width: 900px !important;
        }
  
        .row-content {
          width: 100% !important;
          max-width: 900px !important;
          margin: 0 auto;
        }
  
        table {
          max-width: 100% !important;
          width: 100% !important;
          height: auto !important;
        }
  
        .desktop_hide, .mobile_hide {
          display: none !important;
        }
        .student-wrapper {
          width: 100%;
        }
        .student-wrapper__img {
          width: 200px;
        }
        .student-wrapper img {
          width: 100px !important;
          height: 100px !important;
          object-fit: cover;
          border-radius: 50% !important;
        }
      }
    </style>
  </head>
  
  <body class="body" style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
      <tbody>
        <tr>
          <td>
          <div class="header-container">
            <img src="${schoolLogo}" alt="School Logo" />
            <div class="header-title">Μαθητολόγιο ${populateGrades()}</div>
          </div>
            <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
              <tbody>
                <tr>
                  <td>
                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                      <tbody>
                        <tr>
                          <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                            <table class="divider_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                              <tr>
                                <td class="">
                                  <div class="alignment" align="center">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                      <tr>
                                        <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #dddddd;"><span style="word-break: break-word;">&#8202;</span></td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 900px; margin: 0 auto;" width="900">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                          <table class="heading_block block-1" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                            <tr>
                              <td class="pad">
                                <h1 style="margin: 0; color: #000000; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 20px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 24px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Διαγωνίσματα:</span></h1>
                              </td>
                            </tr>
                          </table>
                          <table class="table_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                            <tr>
                              <td class="pad">
                                <table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; width: 100%; table-layout: fixed; direction: ltr; background-color: transparent; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-weight: 400; color: #101112; text-align: left; letter-spacing: 0px; word-break: break-all;" width="100%">
                                  <thead style="vertical-align: top; background-color: #f2f2f2; color: #101112; font-size: 14px; line-height: 120%; text-align: center;">
                                    <tr>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Ονοματεπώνυμο<br></th>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Γονέας 1<br></th>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τηλ Γονέα 1<br></th>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Γονέας 2<br></th>
                                      <th width="20%" style="padding: 10px; word-break: break-word; font-weight: 700; border-top: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; border-left: 1px solid #dddddd;">Τηλ Γονέα 2<br></th>
                                    </tr>
                                  </thead>
                                  <tbody style="vertical-align: top; font-size: 16px; line-height: 120%;">
                                  ${populateUsers()}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
      `;

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.1 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "80vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const handlePrint = useReactToPrint({
    content: () => htmlRef.current, // Reference to the element you want to print
    documentTitle: "Student Report", // Optional: Title for the print document
  });

  return (
    <div
      ref={containerRef}
      className={
        "print-total-users " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "print-total-users__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="print-total-users__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="html-container" ref={htmlRef}>
              <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
            </div>
            <ClassSelector
              classes={classes}
              selectedGrades={selectedGrades}
              setSelectedGrades={setSelectedGrades}
              selectedClasses={selectedClasses}
              setSelectedClasses={setSelectedClasses}
            />
            <div className="print-total-users__config-actions">
              <button className="cta" onClick={() => handlePrint()}>
                Εκτύπωση
              </button>
            </div>
            <div
              className="print-total-users__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="print-total-users__preview">
            <BiPrinter
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default PrintTotalUsers;

import React, { useState, useEffect, useContext, useRef } from "react";
import "./date-section.scss";
import { SocketContext } from "../../../../app/socket";
import Draggable from "react-draggable";
import { useEventContext } from "../../eventContext";
import { useSelector } from "react-redux";
import { iconStyle } from "../../../../utils/generalUtils";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

function RoundHalfDown(num) {
  return Math.round(num / 15) * 15;
}

const TimeSlot = ({ hour, isNotWorking, workingHours }) => (
  <div
    className={`time-slot ${
      workingHours && workingHours.length && isNotWorking ? "not-working" : ""
    }`}
  >
    <span className="time-slot__hour">{hour}:00</span>
  </div>
);

function getMinutes(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  return hours * 60 + minutes;
}

function formatDateTime(dateObj) {
  const y = dateObj.getFullYear();
  const m = String(dateObj.getMonth() + 1).padStart(2, "0");
  const d = String(dateObj.getDate()).padStart(2, "0");
  const hh = String(dateObj.getHours()).padStart(2, "0");
  const mm = String(dateObj.getMinutes()).padStart(2, "0");
  return `${y}-${m}-${d} ${hh}:${mm}:00`;
}

function TimeLine({ eventDepartment, selectedClassrooms, calendarDate }) {
  const socketContext = useContext(SocketContext);
  const {
    eventStartDate,
    setEventStartDate,
    eventEndDate,
    setEventEndDate,
    eventId,
    finalTestForErrors,
    setFinalTestForErrors,
    setNoFinalErrors,
    setErrorMsg,
    eventRepeatType,
    setEventRepeatType,
    eventType,
    setEventOld,
  } = useEventContext();
  const generalValues = useSelector((state) => state.profile.generalValues);
  const [workingDays, setWorkingDays] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [isNotWorkingDayFlag, setIsNotWorkingDayFlag] = useState(false);
  const [isNotWorkingHoursFlags, setIsNotWorkingHoursFlags] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const scrollableRef = useRef(null);
  const isNewEvent = !eventId;
  const now = new Date();
  const today21_59 = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    21,
    59
  );
  let defaultStart;
  let defaultEnd;
  if (isNewEvent) {
    if (now < today21_59) {
      defaultStart = new Date(now);
      defaultEnd = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    } else {
      const dateBase = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        21,
        0
      );
      defaultStart = dateBase;
      defaultEnd = new Date(
        dateBase.getFullYear(),
        dateBase.getMonth(),
        dateBase.getDate(),
        23,
        0
      );
    }
  } else {
    defaultStart = new Date(eventStartDate);
    defaultEnd = new Date(eventEndDate);
  }
  const [startHour, setStartHour] = useState(defaultStart.getHours());
  const [startMinute, setStartMinute] = useState(defaultStart.getMinutes());
  const [endHour, setEndHour] = useState(defaultEnd.getHours());
  const [endMinute, setEndMinute] = useState(defaultEnd.getMinutes());
  const [startHourFocused, setStartHourFocused] = useState(false);
  const [startMinuteFocused, setStartMinuteFocused] = useState(false);
  const [endHourFocused, setEndHourFocused] = useState(false);
  const [endMinuteFocused, setEndMinuteFocused] = useState(false);

  useEffect(() => {
    if (
      !startHourFocused &&
      !startMinuteFocused &&
      !endHourFocused &&
      !endMinuteFocused
    ) {
      validateTimes();
    }
  }, [startHourFocused, startMinuteFocused, endHourFocused, endMinuteFocused]);

  const validateTimes = () => {
    if (
      startHour > endHour ||
      (startHour === endHour && startMinute >= endMinute)
    ) {
      const newEndHour = startHour < 23 ? startHour + 1 : startHour;
      setEndHour(newEndHour);
      setEndMinute(0);
    }
  };

  useEffect(() => {
    if (calendarDate && !isNaN(new Date(calendarDate))) {
      const baseDate = new Date(calendarDate);
      const startDateTime = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate(),
        startHour,
        startMinute
      );
      const endDateTime = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate(),
        endHour,
        endMinute
      );
      setEventStartDate(formatDateTime(startDateTime));
      setEventEndDate(formatDateTime(endDateTime));
    } else {
      const startDateTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        startHour,
        startMinute
      );
      const endDateTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        endHour,
        endMinute
      );
      setEventStartDate(formatDateTime(startDateTime));
      setEventEndDate(formatDateTime(endDateTime));
    }
  }, [startHour, startMinute, endHour, endMinute, calendarDate]);

  useEffect(() => {
    if (
      generalValues &&
      generalValues.length &&
      generalValues.find((value) => value.name === "working_days")
    ) {
      try {
        let savedWorkingDays = generalValues.find(
          (value) => value.name === "working_days"
        ).value;
        setWorkingDays(JSON.parse(savedWorkingDays));
      } catch (e) {
        console.log(e);
      }
    }
    if (
      generalValues &&
      generalValues.length &&
      generalValues.find((value) => value.name === "working_hours")
    ) {
      try {
        let savedWorkingHours = generalValues.find(
          (value) => value.name === "working_hours"
        ).value;
        setWorkingHours(JSON.parse(savedWorkingHours));
      } catch (e) {
        console.log(e);
      }
    }
  }, [generalValues]);

  useEffect(() => {
    if (!calendarDate) return;
    const dayOfWeek = calendarDate ? new Date(calendarDate).getDay() : 1;
    const isDayNotWorking = isNotWorkingDay(dayOfWeek);
    setIsNotWorkingDayFlag(isDayNotWorking);
    const flags = [...Array(24).keys()].map((hour) => {
      const isHourNotWorking = isNotWorkingHour(dayOfWeek, hour);
      return isHourNotWorking;
    });
    setIsNotWorkingHoursFlags(flags);
  }, [calendarDate, workingDays, workingHours]);

  const isNotWorkingDay = (dayOfWeek) => {
    if (!workingDays || workingDays.length === 0) return false;
    return !workingDays.includes(dayOfWeek);
  };

  const isNotWorkingHour = (dayOfWeek, hour) => {
    if (!workingHours || workingHours.length === 0) return false;
    const workingHoursForDay = workingHours[dayOfWeek];
    if (!workingHoursForDay) return true;
    const startMinutes = getMinutes(workingHoursForDay.start_hour);
    const endMinutes = getMinutes(workingHoursForDay.finish_hour);
    const currentMinutes = hour * 60;
    return currentMinutes < startMinutes || currentMinutes >= endMinutes;
  };

  useEffect(() => {
    getCurrentEvents();
  }, [eventStartDate]);

  useEffect(() => {
    if (finalTestForErrors) {
      setFinalTestForErrors(false);
      const errs = checkForErrors();
      if (errs && errs.length) {
        setNoFinalErrors("has-error");
        setErrorMsg(errs);
      } else {
        setNoFinalErrors("no-errors");
        setErrorMsg("no-error");
      }
    }
  }, [finalTestForErrors]);

  const checkForErrors = () => {
    function doEventsOverlap(event1, event2) {
      const start1 =
        new Date(event1.start).getHours() * 60 +
        new Date(event1.start).getMinutes();
      const end1 =
        new Date(event1.end).getHours() * 60 +
        new Date(event1.end).getMinutes();
      const start2 =
        new Date(event2.start).getHours() * 60 +
        new Date(event2.start).getMinutes();
      const end2 =
        new Date(event2.end).getHours() * 60 +
        new Date(event2.end).getMinutes();
      return !(end1 <= start2 || start1 >= end2);
    }
    let msg = "";
    const overlapFound = currentEvents.some((event) => {
      let event1 = {
        start: new Date(event.start_at),
        end: new Date(event.finish_at),
      };
      let event2 = {
        start: new Date(eventStartDate),
        end: new Date(eventEndDate),
      };
      if (doEventsOverlap(event1, event2)) {
        let classroomsOverlap = false;
        let professorOverlap = false;
        let departmentOverlap = false;
        let type = "μάθημα";
        if (eventType === "exam") {
          type = "διαγώνισμα";
        } else if (eventType === "test") {
          type = "τεστ";
        }
        let overlapType = "μάθημα";
        if (event.type === "exam") {
          overlapType = "διαγώνισμα";
        } else if (event.type === "test") {
          overlapType = "τεστ";
        }
        try {
          let eventClassrooms = JSON.parse(event.classroom_id);
          eventClassrooms.forEach((classroom) => {
            if (eventClassrooms.includes(classroom)) {
              classroomsOverlap = true;
            }
          });
        } catch (e) {
          console.log(e);
        }
        if (classroomsOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με την ίδια αίθουσα.";
        } else if (departmentOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με το ίδιο τμήμα.";
        } else if (professorOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με τον ίδιο καθηγητή.";
        } else {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType;
        }
        return true;
      }
      return false;
    });
    return msg;
  };

  const defaultStartMinutes = startHour * 60 + startMinute - 1440;
  const defaultEndMinutes = endHour * 60 + endMinute - 1440;
  const [eventStartMinutes, setEventStartMinutes] =
    useState(defaultStartMinutes);
  const [eventEndMinutes, setEventEndMinutes] = useState(defaultEndMinutes);

  useEffect(() => {
    setEventStartMinutes(startHour * 60 + startMinute - 1440);
  }, [startHour, startMinute]);

  useEffect(() => {
    setEventEndMinutes(endHour * 60 + endMinute - 1440);
  }, [endHour, endMinute]);

  useEffect(() => {
    getCurrentEvents();
  }, [selectedClassrooms]);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 500;
    }
  }, [scrollableRef]);

  const isPastDate = () => {
    if (eventType === "test") {
      const currentDate = new Date();
      const selectedDate = new Date(calendarDate);
      if (
        currentDate.getFullYear() > selectedDate.getFullYear() ||
        (currentDate.getFullYear() === selectedDate.getFullYear() &&
          currentDate.getMonth() > selectedDate.getMonth()) ||
        (currentDate.getFullYear() === selectedDate.getFullYear() &&
          currentDate.getMonth() === selectedDate.getMonth() &&
          currentDate.getDate() > selectedDate.getDate())
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (
      window.location.hostname.includes("simmetria") ||
      window.location.hostname.includes("localhost")
    ) {
      if (isPastDate()) {
        setEventRepeatType("");
        setEventOld(true);
      } else {
        setEventOld(false);
      }
    }
  }, [calendarDate]);

  const getCurrentEvents = () => {
    let actualDate = eventStartDate ? eventStartDate : calendarDate;
    actualDate = new Date(actualDate);
    if (actualDate && !isNaN(actualDate)) {
      const args = {
        day: actualDate.getDate(),
        month: actualDate.getMonth(),
        year: actualDate.getFullYear(),
        classroom_id: selectedClassrooms,
        department_id: eventDepartment,
        event_id: eventId ? eventId : "",
      };
      const getEventsListener = (data) => {
        setCurrentEvents(data);
      };
      socketContext.socket.off("eventsAvailability", getEventsListener);
      socketContext.socket.on("eventsAvailability", getEventsListener);
      socketContext.socket.emit("getEventsAvailability", args);
      socketContext.socket.off("refreshEventsAvailability");
      socketContext.socket.on("refreshEventsAvailability", () => {
        socketContext.socket.emit("getEventsAvailability", args);
      });
    }
  };

  const handleDragStop = (newStartPixel) => {
    const duration = eventEndMinutes - eventStartMinutes;
    let newStartMinutes = Math.floor(RoundHalfDown(newStartPixel));
    setEventStartMinutes(newStartMinutes);
    setEventEndMinutes(newStartMinutes + duration);
    const actualStart = newStartMinutes + 1440;
    const actualEnd = newStartMinutes + duration + 1440;
    const startH = Math.floor(actualStart / 60);
    const startM = actualStart % 60;
    const endH = Math.floor(actualEnd / 60);
    const endM = actualEnd % 60;
    setStartHour(startH);
    setStartMinute(startM);
    setEndHour(endH);
    setEndMinute(endM);
  };

  const handleDrag = (e, data) => {
    const { y } = data;
    const buffer = 30;
    const { scrollTop, offsetHeight } = scrollableRef.current;
    if (y + 1440 - scrollTop > offsetHeight - buffer) {
      scrollableRef.current.scrollTop += buffer;
    }
  };

  const populateCurrentEvents = () => {
    return currentEvents.map((event, key) => {
      const eventStart = new Date(event.start_at);
      const eventEnd = new Date(event.finish_at);
      const topPosition = eventStart.getHours() * 60 + eventStart.getMinutes();
      const bottomPosition = eventEnd.getHours() * 60 + eventEnd.getMinutes();
      const height = bottomPosition - topPosition;
      return (
        <div
          key={`event-${key}`}
          className="time-slot__taken"
          style={{
            position: "absolute",
            top: `${topPosition}px`,
            height: `${height}px`,
            width: "100%",
          }}
        >
          <span className="time-slot__taken-title">
            {event.title} -{" "}
            {event.type === "lecture"
              ? "Μάθημα"
              : event.type === "exam"
              ? "Διαγώνισμα"
              : "Τεστ"}
          </span>
        </div>
      );
    });
  };

  const handleStartHourChange = (value) => {
    let val = Math.min(23, Math.max(0, value));
    setStartHour(val);
  };

  const handleStartMinuteChange = (value) => {
    let val = Math.min(59, Math.max(0, value));
    setStartMinute(val);
  };

  const handleEndHourChange = (value) => {
    let val = Math.min(23, Math.max(0, value));
    setEndHour(val);
  };

  const handleEndMinuteChange = (value) => {
    let val = Math.min(59, Math.max(0, value));
    setEndMinute(val);
  };

  const getEventStartTime = () => {
    let hr = startHour < 10 ? `0${startHour}` : startHour;
    let mn = startMinute < 10 ? `0${startMinute}` : startMinute;
    return `${hr}:${mn}`;
  };

  const getEventEndTime = () => {
    let hr = endHour < 10 ? `0${endHour}` : endHour;
    let mn = endMinute < 10 ? `0${endMinute}` : endMinute;
    return `${hr}:${mn}`;
  };

  const incrementStartHour = () => handleStartHourChange(startHour + 1);
  const decrementStartHour = () => handleStartHourChange(startHour - 1);
  const incrementStartMinute = () => handleStartMinuteChange(startMinute + 1);
  const decrementStartMinute = () => handleStartMinuteChange(startMinute - 1);
  const incrementEndHour = () => handleEndHourChange(endHour + 1);
  const decrementEndHour = () => handleEndHourChange(endHour - 1);
  const incrementEndMinute = () => handleEndMinuteChange(endMinute + 1);
  const decrementEndMinute = () => handleEndMinuteChange(endMinute - 1);

  return (
    <div
      className={`timeline-wrapper ${
        workingHours && workingHours.length && isNotWorkingDayFlag
          ? "not-working"
          : ""
      }`}
    >
      {isPastDate() && <div className="timeline-wrapper__old-date"></div>}
      {!isPastDate() ? (
        <div className="time">
          <div className="item">
            <label>Ώρα έναρξης:</label>
            <div className="time-input-group">
              <div className="time-input-group__wrapper">
                <BiChevronUp
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={incrementStartHour}
                />
                <input
                  type="number"
                  className="input"
                  min="0"
                  max="23"
                  value={startHour}
                  onFocus={() => setStartHourFocused(true)}
                  onBlur={() => setStartHourFocused(false)}
                  onChange={(e) =>
                    handleStartHourChange(parseInt(e.target.value, 10))
                  }
                />
                <BiChevronDown
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={decrementStartHour}
                />
              </div>
              <span>:</span>
              <div className="time-input-group__wrapper">
                <BiChevronUp
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={incrementStartMinute}
                />
                <input
                  type="number"
                  className="input"
                  min="0"
                  max="59"
                  value={startMinute}
                  onFocus={() => setStartMinuteFocused(true)}
                  onBlur={() => setStartMinuteFocused(false)}
                  onChange={(e) =>
                    handleStartMinuteChange(parseInt(e.target.value, 10))
                  }
                />
                <BiChevronDown
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={decrementStartMinute}
                />
              </div>
            </div>
          </div>
          <div className="item">
            <label>Ώρα λήξης:</label>
            <div className="time-input-group">
              <div className="time-input-group__wrapper">
                <BiChevronUp
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={incrementEndHour}
                />
                <input
                  type="number"
                  className="input"
                  min="0"
                  max="23"
                  value={endHour}
                  onFocus={() => setEndHourFocused(true)}
                  onBlur={() => setEndHourFocused(false)}
                  onChange={(e) =>
                    handleEndHourChange(parseInt(e.target.value, 10))
                  }
                />
                <BiChevronDown
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={decrementEndHour}
                />
              </div>
              <span>:</span>
              <div className="time-input-group__wrapper">
                <BiChevronUp
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={incrementEndMinute}
                />
                <input
                  type="number"
                  className="input"
                  min="0"
                  max="59"
                  value={endMinute}
                  onFocus={() => setEndMinuteFocused(true)}
                  onBlur={() => setEndMinuteFocused(false)}
                  onChange={(e) =>
                    handleEndMinuteChange(parseInt(e.target.value, 10))
                  }
                />
                <BiChevronDown
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                  onClick={decrementEndMinute}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {!isPastDate() ? (
        <div ref={scrollableRef} className="timeline no-scollbar">
          <div style={{ position: "relative", height: "1440px" }}>
            {[...Array(24).keys()].map((hour) => (
              <TimeSlot
                key={"day-hour" + hour}
                hour={hour}
                isNotWorking={isNotWorkingHoursFlags[hour]}
                workingHours={workingHours}
              />
            ))}
            <DraggableEvent
              getEventStartTime={getEventStartTime}
              eventStartMinutes={eventStartMinutes}
              eventEndMinutes={eventEndMinutes}
              onDragStop={handleDragStop}
              getEventEndTime={getEventEndTime}
              handleDrag={handleDrag}
            />
            <div className="time-slot__taken-container">
              {populateCurrentEvents()}
            </div>
            <div
              className="time-slot__current"
              style={{
                top: `${
                  new Date().getHours() * 60 + new Date().getMinutes()
                }px`,
              }}
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}
      {(eventType === "lecture" ||
        eventType === "exam" ||
        eventType === "test") &&
      !isPastDate() ? (
        <div className="repeat-type">
          <span className="label">Επανάληψη μαθήματος</span>
          <select
            value={eventRepeatType}
            onChange={(e) => setEventRepeatType(e.target.value)}
            className="input"
          >
            <option value="">Καμία επανάληψη</option>
            <option value="weekly">Εβδομαδιαία επανάληψη</option>
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const DraggableEvent = ({
  getEventStartTime,
  eventStartMinutes,
  eventEndMinutes,
  onDragStop,
  getEventEndTime,
  handleDrag,
}) => {
  return (
    <Draggable
      axis="y"
      bounds="parent"
      position={{ x: 0, y: RoundHalfDown(eventStartMinutes) }}
      onStop={(e, data) => onDragStop(data.y)}
      onDrag={handleDrag}
    >
      <div
        className="time-slot__event"
        style={{ height: `${eventEndMinutes - eventStartMinutes}px` }}
      >
        <span className="time-slot__event-title">
          {getEventStartTime()} - {getEventEndTime()}
        </span>
      </div>
    </Draggable>
  );
};

export default TimeLine;

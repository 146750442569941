import React, { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ExamGraphElement from "./RenderElements/examGraphElement";
import PrintEvaluation from "./RenderElements/printEvaluation";

const ResponsiveGridLayout = WidthProvider(Responsive);

function formatMySQLTimestamp(mysqlTimestamp) {
  const date = new Date(mysqlTimestamp);

  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 10);
}

// Helper function to check if layouts differ
function layoutsDiffer(oldLayout, newLayout) {
  if (oldLayout.length !== newLayout.length) return true;
  for (let i = 0; i < oldLayout.length; i++) {
    const oldItem = oldLayout[i];
    const newItem = newLayout[i];
    if (
      oldItem.i !== newItem.i ||
      oldItem.x !== newItem.x ||
      oldItem.y !== newItem.y ||
      oldItem.w !== newItem.w ||
      oldItem.h !== newItem.h
    ) {
      return true;
    }
  }
  return false;
}

const LayoutRenderer = ({
  layoutData,
  isEditable = false,
  onLayoutChange = () => {},
  onDrop = () => {},
  onEditClick = () => {},
  onDeleteClick = () => {},
  containerWidth = 700,
  scaleDown = false,
  printMode,
  user,
  exams,
  comments,
  draggingType, // new prop
  evaluation,
  evaluationSettings,
}) => {
  const sizeMapping = {
    text: { w: 12, h: 10 },
    image: { w: 6, h: 20 },
    "school-logo": { w: 6, h: 10 },
    spacer: { w: 12, h: 1 },
    line: { w: 12, h: 1 },
    "user-image": { w: 6, h: 6 },
    "user-full-name": { w: 12, h: 2 },
    "exam-stats": { w: 12, h: 7 },
    "student-comments": { w: 12, h: 7 },
    "test-stats": { w: 12, h: 10 },
    "exam-graph": { w: 12, h: 10 },
    "evaluation-stats": { w: 12, h: 10 },
  };

  // Enhance layout data by storing the original height for exam-stats items.
  const enhancedLayoutData = layoutData.map((item) =>
    (item.type === "exam-stats" ||
      item.type === "test-stats" ||
      item.type == "student-comments") &&
    !item.originalH
      ? { ...item, originalH: item.h }
      : item
  );

  const [internalLayout, setInternalLayout] = useState(enhancedLayoutData);

  // This ref will hold the DOM nodes for each exam-stats content container.
  const examRefs = useRef({});

  // Constants used for calculations.
  const PREVIEW_WIDTH = 200;
  const scaleFactor = scaleDown ? PREVIEW_WIDTH / containerWidth : 1;
  const rglRowHeightPx = 10; // Each grid row is 10px

  // Update layout when layoutData prop changes.
  useEffect(() => {
    const enhancedData = layoutData.map((item) =>
      (item.type === "exam-stats" ||
        item.type === "test-stats" ||
        item.type == "student-comments") &&
      !item.originalH
        ? { ...item, originalH: item.h }
        : item
    );
    setInternalLayout(enhancedData);
  }, [layoutData]);

  useEffect(() => {
    let layoutChanged = false;
    const extraBufferPx = 4;

    const updatedLayout = internalLayout.map((item) => {
      if (item.type === "exam-stats" || item.type == "student-comments") {
        const node = examRefs.current[item.i];
        if (node) {
          const measuredPx = node.getBoundingClientRect().height;
          const unscaledHeight = scaleDown
            ? measuredPx / scaleFactor
            : measuredPx;
          const adjustedHeight = unscaledHeight + extraBufferPx;
          const newGridUnits = Math.ceil(adjustedHeight / rglRowHeightPx);
          if (newGridUnits !== item.h) {
            layoutChanged = true;
            return { ...item, h: newGridUnits };
          }
        }
      }
      return item;
    });
    if (layoutChanged && layoutsDiffer(internalLayout, updatedLayout)) {
      setInternalLayout(updatedLayout);
      onLayoutChange(updatedLayout);
    }
  }, [
    printMode,
    exams,
    internalLayout,
    onLayoutChange,
    scaleDown,
    scaleFactor,
    rglRowHeightPx,
  ]);

  const layouts = {
    lg: internalLayout,
    md: internalLayout,
    sm: internalLayout,
    xs: internalLayout,
    xxs: internalLayout,
  };

  const handleLayoutChange = (newLayout) => {
    if (!isEditable) return;
    if (layoutsDiffer(internalLayout, newLayout)) {
      setInternalLayout(newLayout);
      onLayoutChange(newLayout);
    }
  };

  const droppingItem =
    draggingType && sizeMapping[draggingType]
      ? { i: "drop", ...sizeMapping[draggingType] }
      : { i: "drop", w: 1, h: 1 }; // default fallback
  return (
    <div
      style={{
        width: scaleDown ? PREVIEW_WIDTH : containerWidth,
        position: "relative",
      }}
    >
      <div
        style={{
          width: containerWidth,
          transform: `scale(${scaleFactor})`,
          transformOrigin: "top left",
        }}
      >
        <ResponsiveGridLayout
          className="layout"
          margin={[0, 0]}
          resizeHandles={["se", "sw", "ne", "nw"]}
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={rglRowHeightPx}
          width={containerWidth}
          compactType="vertical"
          isDroppable={isEditable}
          isDraggable={isEditable}
          isResizable={isEditable}
          onDrop={onDrop}
          onLayoutChange={handleLayoutChange}
          draggableHandle=".drag-handle"
          droppingItem={droppingItem}
        >
          {internalLayout.map((item) => {
            return (
              <div className="grid-item" key={item.i} style={item.style || {}}>
                {isEditable && (
                  <>
                    <div className="drag-handle">Drag</div>
                    <button
                      className="edit-button"
                      onClick={() => onEditClick(item.i)}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => onDeleteClick(item.i)}
                    >
                      Delete
                    </button>
                  </>
                )}

                {item.type === "text" && (
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                )}

                {item.type === "image" && (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: item.style?.objectFit || "cover",
                    }}
                    src="https://t4.ftcdn.net/jpg/07/40/34/41/360_F_740344153_IQT8C4zfJj81LWvYcBfCpQXaO1lNeIXW.jpg"
                    alt="Preview"
                  />
                )}

                {item.type === "school-logo" && (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: item.style?.objectFit || "cover",
                    }}
                    src="https://md.edupal.gr/resources/logos/edupal.png"
                    alt="Preview"
                  />
                )}

                {item.type === "spacer" && <div></div>}

                {item.type === "line" && (
                  <div
                    style={{ width: "100%", height: "1px", background: "#ccc" }}
                  />
                )}

                {item.type === "user-image" && (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: item.style?.objectFit || "cover",
                      borderRadius: item.style?.borderRadius || "50%",
                    }}
                    src="../resources/student.png"
                    alt="User"
                  />
                )}

                {item.type === "user-full-name" && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <span>
                      {user
                        ? user.last_name + " " + user.first_name
                        : "Ονοματεπώνυμο Μαθητή"}
                    </span>
                  </div>
                )}
                {item.type == "exam-graph" ? (
                  <ExamGraphElement
                    item={item}
                    exams={
                      printMode
                        ? exams.filter((exm) => exm.score || exm.score == 0)
                        : [
                            {
                              id: 1,
                              start_at: "2024-02-01T10:30:00Z",
                              score: 85,
                            },
                            {
                              id: 2,
                              start_at: "2024-02-05T14:00:00Z",
                              score: 78,
                            },
                            {
                              id: 3,
                              start_at: "2024-02-10T09:15:00Z",
                              score: 92,
                            },
                            {
                              id: 4,
                              start_at: "2024-02-15T16:45:00Z",
                              score: 67,
                            },
                            {
                              id: 5,
                              start_at: "2024-02-20T12:20:00Z",
                              score: 88,
                            },
                            {
                              id: 6,
                              start_at: "2024-02-25T08:00:00Z",
                              score: 74,
                            },
                          ]
                    }
                  />
                ) : (
                  ""
                )}
                {item.type == "evaluation-stats" ? (
                  <PrintEvaluation
                    item={item}
                    evaluation={printMode ? evaluation : []}
                    evaluationSettings={printMode ? evaluationSettings : []}
                  />
                ) : (
                  ""
                )}
                {item.type === "exam-stats" && (
                  // Attach the ref here so we measure only the table content.
                  <div
                    className="preview-exam-stats"
                    ref={(el) => (examRefs.current[item.i] = el)}
                  >
                    <table style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          {item.tableData.columns
                            .filter((col) => col.shown)
                            .map((col, idx) => (
                              <th key={idx} style={{ width: col.width + "%" }}>
                                {col.label}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {printMode && exams ? (
                          exams.map((exam, rowIndex) => (
                            <tr key={rowIndex}>
                              {item.tableData.columns
                                .filter((col) => col.shown)
                                .map((col, colIndex) => (
                                  <td
                                    key={colIndex}
                                    style={{ width: col.width + "%" }}
                                  >
                                    {col.type == "class" ? exam.class_name : ""}
                                    {col.type == "date"
                                      ? formatMySQLTimestamp(exam.start_at)
                                      : ""}
                                    {col.type == "professor"
                                      ? exam.professor_name
                                      : ""}
                                    {col.type == "grade" ? exam.score : ""}
                                  </td>
                                ))}
                            </tr>
                          ))
                        ) : (
                          // When not in print mode, show one row with placeholder data.
                          <tr>
                            {item.tableData.columns
                              .filter((col) => col.shown)
                              .map((col, colIndex) => (
                                <td
                                  key={colIndex}
                                  style={{ width: col.width + "%" }}
                                >
                                  Placeholder
                                </td>
                              ))}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {item.type === "student-comments" && (
                  // Attach the ref here so we measure only the table content.
                  <div
                    className="preview-exam-stats"
                    ref={(el) => (examRefs.current[item.i] = el)}
                  >
                    <table style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr>
                          {item.tableData.columns
                            .filter((col) => col.shown)
                            .map((col, idx) => (
                              <th key={idx} style={{ width: col.width + "%" }}>
                                {col.label}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {printMode && comments ? (
                          comments.map((comment, rowIndex) => (
                            <tr key={rowIndex}>
                              {item.tableData.columns
                                .filter((col) => col.shown)
                                .map((col, colIndex) => (
                                  <td
                                    key={colIndex}
                                    style={{ width: col.width + "%" }}
                                  >
                                    {col.type == "class"
                                      ? comment.class_name
                                      : ""}
                                    {col.type == "period" ? comment.period : ""}
                                    {col.type == "professor"
                                      ? comment.creator_first_name
                                      : ""}
                                    {col.type == "comment"
                                      ? comment.description
                                      : ""}
                                  </td>
                                ))}
                            </tr>
                          ))
                        ) : (
                          // When not in print mode, show one row with placeholder data.
                          <tr>
                            {item.tableData.columns
                              .filter((col) => col.shown)
                              .map((col, colIndex) => (
                                <td
                                  key={colIndex}
                                  style={{ width: col.width + "%" }}
                                >
                                  Placeholder
                                </td>
                              ))}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default LayoutRenderer;

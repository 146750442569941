import React, { useContext, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { SocketContext } from "../../app/socket";
import LayoutRenderer from "./layoutRenderer";
import "./template-editor.scss";

import ToolboxWrapper from "./ToolboxWrapper/toolboxWrapper";

const ResponsiveGridLayout = WidthProvider(Responsive);

const TemplateEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [layout, setLayout] = useState([]);
  const socket = useContext(SocketContext).socket;
  const [printTemplatesId, setPrintTemplatesId] = useState(null);
  const [template, setTemplate] = useState(null);
  const [draggingType, setDraggingType] = useState(null);

  useEffect(() => {
    const p = new URLSearchParams(location.search);
    const tid = p.get("template-id");
    document.body.style.overflowY = "auto";
    if (!tid) {
      navigate("/print");
    } else {
      setPrintTemplatesId(tid);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (printTemplatesId) getTemplate();
    // Cleanup
    return () => {
      if (socket && printTemplatesId) {
        socket.off("printTemplates" + printTemplatesId);
        socket.off("refreshPrintTemplates");
      }
    };
  }, [printTemplatesId, socket]);

  function getTemplate() {
    const args = { print_templates_id: printTemplatesId };
    socket.on("printTemplates" + printTemplatesId, (data) => {
      if (data && data[0]) setTemplate(data[0]);
      else setTemplate(null);
    });
    socket.emit("getPrintTemplates", args);
    socket.on("refreshPrintTemplates", () => {
      socket.emit("getPrintTemplates", args);
    });
  }

  useEffect(() => {
    if (template?.template_data) {
      try {
        setLayout(JSON.parse(template.template_data));
      } catch {
        setLayout([]);
      }
    } else {
      setLayout([]);
    }
  }, [template]);

  const saveTemplate = () => {
    const body = {
      print_template_id: printTemplatesId,
      template_data: layout,
    };
    console.log(body);
    socket.emit("editPrintTemplateData", body);
  };

  const [editingComponentId, setEditingComponentId] = useState(null);

  const layouts = {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout,
  };

  const onLayoutChange = (newLayout) => {
    setLayout((prev) =>
      prev.map((item) => {
        const updated = newLayout.find((l) => l.i === item.i);
        return updated ? { ...item, ...updated } : item;
      })
    );
  };

  const handleDeleteItem = (itemId) => {
    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== itemId));
  };

  const onDrop = (currentLayout, layoutItem, event) => {
    const componentType = event.dataTransfer.getData("componentType");
    if (!componentType) return;

    const newId = "component-" + Date.now();

    // Define initial sizes for each component type
    const initialSizes = {
      text: { w: 12, h: 10 },
      image: { w: 6, h: 20 },
      "school-logo": { w: 6, h: 10 },
      spacer: { w: 12, h: 1 },
      line: { w: 12, h: 1 },
      "user-image": { w: 6, h: 6 },
      "user-full-name": { w: 12, h: 2 },
      "exam-stats": { w: 12, h: 7 },
      "student-comments": { w: 12, h: 7 },
      "test-stats": { w: 12, h: 10 },
      "exam-graph": { w: 12, h: 10 },
      "evaluation-stats": { w: 12, h: 10 },
    };

    // Use default size if not defined in the mapping
    const size = initialSizes[componentType] || { w: 6, h: 6 };

    // Start with a basic item configuration
    let finalItem = {
      ...layoutItem,
      i: newId,
      type: componentType,
      w: size.w,
      h: size.h,
      content: "Κείμενο!",
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
    };

    if (componentType === "exam-stats") {
      finalItem = {
        ...finalItem,
        tableData: {
          columns: [
            { type: "date", label: "Ημερομηνία", shown: true, width: 25 },
            { type: "class", label: "Μάθημα", shown: true, width: 25 },
            { type: "professor", label: "Καθηγητής", shown: true, width: 25 },
            { type: "grade", label: "Βαθμός", shown: true, width: 25 },
            { type: "comments", label: "Σχόλια", shown: false, width: 25 },
          ],
        },
        isResizable: false,
        isDraggable: true,
        style: {
          minHeight: "unset",
        },
      };
    } else if (componentType === "student-comments") {
      finalItem = {
        ...finalItem,
        tableData: {
          columns: [
            { type: "priod", label: "Περίοδος", shown: true, width: 25 },
            { type: "class", label: "Μάθημα", shown: true, width: 25 },
            { type: "professor", label: "Καθηγητής", shown: true, width: 25 },
            { type: "comment", label: "Σχόλιο", shown: true, width: 25 },
          ],
        },
        isResizable: false,
        isDraggable: true,
        style: {
          minHeight: "unset",
        },
      };
    } else if (componentType === "test-stats") {
      finalItem = {
        ...finalItem,
        tableData: {
          columns: [
            { type: "date", label: "Ημερομηνία", shown: true, width: 25 },
            { type: "class", label: "Μάθημα", shown: true, width: 25 },
            { type: "chapter", label: "Κεφάλαιο", shown: true, width: 25 },
            { type: "grade", label: "Βαθμός", shown: true, width: 25 },
            { type: "comments", label: "Σχόλια", shown: false, width: 25 },
          ],
        },
        isResizable: false,
        isDraggable: true,
      };
    }

    setLayout((prev) => [...prev, finalItem]);
  };

  const handleEditClick = (itemId) => {
    setEditingComponentId(itemId);
  };

  const handleUpdateItem = (updatedItem) => {
    setLayout((prev) =>
      prev.map((item) => (item.i === updatedItem.i ? updatedItem : item))
    );
  };

  const handleStopEditing = () => {
    setEditingComponentId(null);
  };

  return (
    <div className="template-editor-wrapper">
      <div className="editor-container">
        <ToolboxWrapper
          layout={layout}
          editingComponentId={editingComponentId}
          onUpdateItem={handleUpdateItem}
          onStopEditing={handleStopEditing}
          onComponentDragStart={(type) => setDraggingType(type)} // new callback
        />

        <div className="layout-container" style={{ width: "700px" }}>
          <LayoutRenderer
            layoutData={layout}
            isEditable={true}
            onLayoutChange={onLayoutChange}
            onDrop={onDrop}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteItem}
            containerWidth={700}
            scaleDown={false}
            draggingType={draggingType} // pass dragging type down
          />
        </div>
      </div>
      <div
        className="template-editor-wrapper__save"
        onClick={() => saveTemplate()}
      >
        <button className="cta cta-fill">Aποθήκευση</button>
      </div>
    </div>
  );
};

export default TemplateEditor;

import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import "./select-users.scss";
import {
  BiBookOpen,
  BiCalendarWeek,
  BiCheck,
  BiChevronsDown,
  BiChevronsUp,
  BiHome,
  BiTable,
  BiUser,
} from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import { useTranslation } from "react-i18next";
import PrintGradeItem from "./PrintGradeItem/printGradeItem";
import { usePrintSectionContext } from "../../pages/PrintPage/TemplateItem/PrintSection/printSectionContext";

function SelectUsers({}) {
  const { t } = useTranslation();
  const socketContext = useContext(SocketContext);
  const {
    grades,
    setGrades,
    selectedGrades,
    setSelectedGrades,
    classes,
    setClasses,
    selectedClasses,
    setSelectedClasses,
    departments,
    setDepartments,
    selectedDepartments,
    setSelectedDepartments,
    users,
    setUsers,
    selectedUsers,
    setSelectedUsers,
    handleGradeClick,
    handleClassClick,
    handleDepartmentClick,
    handleUserClick,
    departmentHasUsers,
    setDepartmentHasUsers,
    usersInDepartments,
    openGrade,
    setOpenGrade,
    openClass,
    setOpenClass,
    openDepartment,
    setOpenDepartment,
  } = usePrintSectionContext();

  const [openUser, setOpenUser] = useState();

  useEffect(() => {
    const cleanUpGetGrades = getGrades();
    const cleanUpGetClasses = getClasses();
    const cleanUpGetDepartments = getDepartments();
    const cleanUpGetUsers = getUsers();
    const cleanUpGetDepartmentHasUsers = getDepartmentHasUsers();
    return () => {
      cleanUpGetGrades();
      cleanUpGetClasses();
      cleanUpGetDepartments();
      cleanUpGetUsers();
      cleanUpGetDepartmentHasUsers();
    };
  }, []);

  const getDepartmentHasUsers = () => {
    let args = {};
    const getDepartmentHasUsersListener = (data) => {
      setDepartmentHasUsers(data);
    };
    socketContext.socket.on(
      "departmentHasUsers",
      getDepartmentHasUsersListener
    );
    socketContext.socket.emit("getDepartmentHasUsers", args);
    return () => {
      socketContext.socket.off(
        "departmentHasUsers",
        getDepartmentHasUsersListener
      );
    };
  };

  const getUsers = () => {
    let args = { type: "student" };
    const getUsersListener = (data) => {
      setUsers(data);
    };
    socketContext.socket.on("allUsersWithParams", getUsersListener);
    socketContext.socket.emit("getAllUsersWithParams", args);
    return () => {
      socketContext.socket.off("allUsersWithParams", getUsersListener);
    };
  };

  const getGrades = () => {
    let args = {};
    const getGradesListener = (data) => {
      setGrades(data);
    };
    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);
    return () => {
      socketContext.socket.off("grades", getGradesListener);
    };
  };

  const getClasses = () => {
    let args = {};
    const getClassesListener = (data) => {
      setClasses(data);
    };
    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", () => {
      socketContext.socket.emit("getClasses", args);
    });
    return () => {
      socketContext.socket.off("classes", getClassesListener);
    };
  };

  const getDepartments = () => {
    let args = {};
    const getDepartmentsListener = (data) => {
      setDepartments(data);
    };
    socketContext.socket.on("departments", getDepartmentsListener);
    socketContext.socket.emit("getDepartments", args);
    socketContext.socket.on("refreshDepartments", () => {
      socketContext.socket.emit("getDepartments", args);
    });
    return () => {
      socketContext.socket.off("departments", getDepartmentsListener);
    };
  };

  // -------------------     grades       -------------------

  const handleSelectAll = () => {
    if (grades.every((grd) => selectedGrades.includes(grd.grade_id))) {
      setSelectedGrades([]);
      setSelectedClasses([]);
      setSelectedDepartments([]);
      setSelectedUsers([]);
    } else {
      grades.map((grd) => {
        if (!selectedGrades.includes(grd.grade_id)) {
          handleGradeClick(grd.grade_id);
        }
      });
    }
  };

  // const handleGradeClick = (gradeId) => {
  //   if (selectedGrades.includes(gradeId)) {
  //     setSelectedGrades((prev) => prev.filter((id) => id !== gradeId));
  //     const classesWithGrade = classes
  //       .filter((cls) => cls.grade_id == gradeId)
  //       .map((cls) => cls.class_id);
  //     const departmentsWithGrade = departments
  //       .filter((dep) => classesWithGrade.includes(dep.class_id))
  //       .map((dep) => dep.department_id);

  //     const usersWithGrade = users
  //       .filter((usr) =>
  //         departmentHasUsers.find(
  //           (dep_users) =>
  //             dep_users.user_id == usr.user_id &&
  //             departmentsWithGrade.includes(
  //               (dep) => dep == dep_users.department_id
  //             )
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedClasses((prev) =>
  //       prev.filter((id) => !classesWithGrade.includes(id))
  //     );
  //     setSelectedDepartments((prev) =>
  //       prev.filter((id) => !departmentsWithGrade.includes(id))
  //     );
  //     setSelectedUsers((prev) =>
  //       prev.filter((id) => !usersWithGrade.includes(id))
  //     );
  //   } else {
  //     setSelectedGrades((prev) => [...prev.filter((id) => id !== -1), gradeId]);
  //     const newClassIds = classes
  //       .filter((cls) => cls.grade_id === gradeId)
  //       .map((cls) => cls.class_id);

  //     setSelectedClasses((prev) => {
  //       const combined = [...prev, ...newClassIds];
  //       return [...new Set(combined)];
  //     });

  //     const newDepartmentIds = departments
  //       .filter((dep) => newClassIds.includes(dep.class_id))
  //       .map((dep) => dep.department_id);

  //     setSelectedDepartments((prev) => {
  //       const combined = [...prev, ...newDepartmentIds];
  //       return [...new Set(combined)];
  //     });

  //     const newUserIds = users
  //       .filter((usr) =>
  //         newDepartmentIds.find((depId) =>
  //           departmentHasUsers.find(
  //             (dep_users) =>
  //               dep_users.user_id == usr.user_id &&
  //               depId == dep_users.department_id
  //           )
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedUsers((prev) => {
  //       const combined = [...prev, ...newUserIds];
  //       return [...new Set(combined)];
  //     });
  //   }
  // };

  const gradeOpen = (gradeId) => {
    setOpenGrade(gradeId);
  };

  const populateGrades = () => {
    return grades.map((grade, index) => {
      return (
        <div
          key={"grade-item" + index}
          onClick={() => gradeOpen(grade.grade_id)}
          className={
            "select-users__item grade " +
            (selectedGrades.includes(grade.grade_id) ? "active" : "")
          }
        >
          <div className="target__select">
            <div className="item">
              <div
                className={
                  "target__select-radio " +
                  (selectedGrades.includes(grade.grade_id) ? "active" : "")
                }
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering gradeOpen
                  handleGradeClick(grade.grade_id); // Ensure handleGradeClick is called
                }}
              >
                {selectedGrades.includes(grade.grade_id) ? (
                  <BiCheck
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <BiCalendarWeek
            size={"30px"}
            color={"#ccc"}
            style={iconStyle("transparent")}
          />
          <span>
            {grade.grade_name} {grade.grade_id}
          </span>
        </div>
      );
    });
  };

  // -------------------     classes       -------------------

  const handleSelectAllClasses = () => {
    const filteredClasses = classes.filter((cls) => cls.grade_id == openGrade);
    if (
      filteredClasses.every((cls) => selectedClasses.includes(cls.class_id))
    ) {
      setSelectedGrades((prev) => prev.filter((id) => id !== openGrade));

      setSelectedClasses([]);
      setSelectedDepartments([]);
      setSelectedUsers([]);
    } else {
      filteredClasses.map((cls) => {
        if (!selectedClasses.includes(cls.class_id)) {
          handleClassClick(cls.class_id);
        }
      });
    }
  };

  useEffect(() => {
    if (openGrade) {
      const filtered = classes.filter((cls) => cls.grade_id == openGrade);
      if (filtered.length == selectedClasses.length) {
        setSelectedGrades((prev) => [
          ...prev.filter((id) => id !== -1),
          openGrade,
        ]);
      }
    }
  }, [selectedClasses]);

  // const handleClassClick = (classId) => {
  //   if (selectedClasses.includes(classId)) {
  //     setSelectedGrades((prev) => prev.filter((id) => id !== openGrade));

  //     setSelectedClasses((prev) => prev.filter((id) => id !== classId));

  //     const departmentsWithClass = departments
  //       .filter((dep) => dep.class_id == classId)
  //       .map((dep) => dep.department_id);
  //     const usersWithClass = users
  //       .filter((usr) =>
  //         departmentHasUsers.find(
  //           (dep_users) =>
  //             dep_users.user_id == usr.user_id &&
  //             departmentsWithClass.includes(
  //               (dep) => dep == dep_users.department_id
  //             )
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedDepartments((prev) =>
  //       prev.filter((id) => !departmentsWithClass.includes(id))
  //     );
  //     setSelectedUsers((prev) =>
  //       prev.filter((id) => !usersWithClass.includes(id))
  //     );
  //   } else {
  //     const filteredClasses = classes
  //       .filter((cls) => cls.grade_id == openGrade)
  //       .map((cls) => cls.class_id);

  //     const filteredClassesSelected = classes
  //       .filter(
  //         (cls) =>
  //           cls.grade_id == openGrade && selectedClasses.includes(cls.class_id)
  //       )
  //       .map((cls) => cls.class_id);

  //     if (filteredClasses.length == filteredClassesSelected.length + 1) {
  //       setSelectedGrades((prev) => [
  //         ...prev.filter((id) => id !== -1),
  //         openGrade,
  //       ]);
  //     }
  //     setSelectedClasses((prev) => [
  //       ...prev.filter((id) => id !== -1),
  //       classId,
  //     ]);
  //     const newDepartmentIds = departments
  //       .filter((dep) => dep.class_id === classId)
  //       .map((dep) => dep.department_id);

  //     setSelectedDepartments((prev) => {
  //       const combined = [...prev, ...newDepartmentIds];
  //       return [...new Set(combined)];
  //     });

  //     const newUserIds = users
  //       .filter((usr) =>
  //         newDepartmentIds.find((depId) =>
  //           departmentHasUsers.find(
  //             (dep_users) =>
  //               dep_users.user_id == usr.user_id &&
  //               depId == dep_users.department_id
  //           )
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedUsers((prev) => {
  //       const combined = [...prev, ...newUserIds];
  //       return [...new Set(combined)];
  //     });
  //   }
  // };

  const classOpen = (classId) => {
    setOpenClass(classId);
  };

  const populateClasses = () => {
    let filteredClasses = classes.filter((cls) => cls.grade_id == openGrade);
    return filteredClasses.map((classItem, index) => {
      return (
        <div
          key={"class-item" + index}
          onClick={() => classOpen(classItem.class_id)}
          className={
            "select-users__item class " +
            (selectedClasses.includes(classItem.class_id) ? "active" : "")
          }
        >
          <div className="target__select">
            <div
              className="item"
              onClick={() => handleClassClick(classItem.class_id)}
            >
              <div
                className={
                  "target__select-radio " +
                  (selectedClasses.includes(classItem.class_id) ? "active" : "")
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleClassClick(classItem.class_id);
                }}
              >
                {selectedClasses.includes(classItem.class_id) ? (
                  <BiCheck
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <BiBookOpen
            size={"30px"}
            color={"#ccc"}
            style={iconStyle("transparent")}
          />
          <span>{classItem.class_name}</span>
        </div>
      );
    });
  };

  // -------------------     departments       -------------------

  const handleSelectAllDepartments = () => {
    const filteredDepartments = departments.filter(
      (dep) => dep.class_id == openClass
    );
    if (
      filteredDepartments.every((dep) =>
        selectedDepartments.includes(dep.department_id)
      )
    ) {
      setSelectedDepartments([]);
      setSelectedUsers([]);
    } else {
      filteredDepartments.map((dep) => {
        if (!selectedDepartments.includes(dep.department_id)) {
          handleDepartmentClick(dep.department_id);
        }
      });
    }
  };

  useEffect(() => {
    if (openClass) {
      const filtered = departments.filter((dep) => dep.class_id == openClass);
      if (filtered.length == selectedDepartments.length) {
        setSelectedClasses((prev) => [
          ...prev.filter((id) => id !== -1),
          openClass,
        ]);
      }
    }
  }, [selectedDepartments]);

  // const handleDepartmentClick = (departmentId) => {
  //   if (selectedDepartments.includes(departmentId)) {
  //     setSelectedGrades((prev) => prev.filter((id) => id !== openGrade));
  //     setSelectedClasses((prev) => prev.filter((id) => id !== openClass));

  //     setSelectedDepartments((prev) =>
  //       prev.filter((id) => id !== departmentId)
  //     );

  //     const usersWithDepartment = users
  //       .filter((usr) =>
  //         departmentHasUsers.find(
  //           (dep_users) =>
  //             dep_users.user_id == usr.user_id &&
  //             dep_users.department_id == departmentId
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedUsers((prev) =>
  //       prev.filter((id) => !usersWithDepartment.includes(id))
  //     );
  //   } else {
  //     setSelectedDepartments((prev) => [
  //       ...prev.filter((id) => id !== -1),
  //       departmentId,
  //     ]);

  //     const newUserIds = users
  //       .filter((usr) =>
  //         departmentHasUsers.includes(
  //           (dep_users) =>
  //             dep_users.user_id == usr.user_id &&
  //             dep_users.department_id == departmentId
  //         )
  //       )
  //       .map((usr) => usr.user_id);

  //     setSelectedUsers((prev) => {
  //       const combined = [...prev, ...newUserIds];
  //       return [...new Set(combined)];
  //     });
  //   }
  // };

  const departmentOpen = (departmentId) => {
    setOpenDepartment(departmentId);
  };

  const populateDepartments = () => {
    let filteredDepartments = departments.filter(
      (dep) => dep.class_id == openClass
    );
    return filteredDepartments.map((dep, index) => {
      return (
        <div
          key={"department-item" + index}
          onClick={() => departmentOpen(dep.department_id)}
          className={
            "select-users__item department " +
            (selectedDepartments.includes(dep.department_id) ? "active" : "")
          }
        >
          <div className="target__select">
            <div
              className="item"
              onClick={() => handleDepartmentClick(dep.department_id)}
            >
              <div
                className={
                  "target__select-radio " +
                  (selectedDepartments.includes(dep.department_id)
                    ? "active"
                    : "")
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleDepartmentClick(dep.department_id);
                }}
              >
                {selectedDepartments.includes(dep.department_id) ? (
                  <BiCheck
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <BiUser
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
          <span>{dep.department_name}</span>
        </div>
      );
    });
  };

  // -------------------     users       -------------------

  const handleSelectAllUsers = () => {
    setSelectedClasses([-1]);
    setSelectedDepartments([-1]);
  };

  // const handleUserClick = (userId) => {
  //   if (selectedUsers.includes(userId)) {
  //     setSelectedUsers((prev) => prev.filter((id) => id !== userId));
  //   } else {
  //     setSelectedUsers((prev) => [...prev.filter((id) => id !== -1), userId]);
  //   }
  // };

  const userOpen = (departmentId) => {
    setOpenDepartment(departmentId);
  };

  useEffect(() => {
    // console.log(usersInDepartments);
  }, [usersInDepartments]);

  useEffect(() => {
    console.log(openDepartment);
    console.log(usersInDepartments);
    if (usersInDepartments && usersInDepartments[openDepartment]) {
      console.log(" --- ");
      console.log(usersInDepartments[openDepartment]);
    }
  }, [openDepartment]);

  const populateUsers = () => {
    const filteredUsers = users.filter((usr) =>
      departmentHasUsers.some(
        (dep_users) =>
          dep_users.user_id == usr.user_id &&
          dep_users.department_id == openDepartment
      )
    );
    return filteredUsers.map((usr, index) => {
      return (
        <div
          key={"user-item" + index}
          className={
            "select-users__item department " +
            (usersInDepartments.openDepartment &&
            usersInDepartments.openDepartment.includes(usr.user_id)
              ? "active"
              : "")
          }
        >
          <div className="target__select">
            <div className="item">
              <div
                className={
                  "target__select-radio " +
                  (usersInDepartments.openDepartment &&
                  usersInDepartments.openDepartment.includes(usr.user_id)
                    ? "active"
                    : "")
                }
                onClick={(e) => {
                  handleUserClick(
                    usr.user_id,
                    openDepartment,
                    openClass,
                    openGrade
                  );
                }}
              >
                {usersInDepartments.openDepartment &&
                usersInDepartments.openDepartment.includes(usr.user_id) ? (
                  <BiCheck
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="select-users__item-usr">
            <img
              className="profile-img__img"
              src={
                usr.profile_picture
                  ? usr.profile_picture
                  : "resources/student.png"
              }
              alt={"student"}
            />
          </div>
          <span>{usr.first_name}</span>
        </div>
      );
    });
  };

  return (
    <div className="select-users">
      <div className="select-users__breadcrumb">
        <div
          className="breadcrumb-item"
          onClick={() => {
            setOpenClass();
            setOpenGrade();
            setOpenDepartment();
          }}
        >
          <BiHome
            size={"20px"}
            color={"#616161"}
            style={iconStyle("transparent")}
          />
        </div>
        {openGrade ? (
          <div
            className="breadcrumb-item"
            onClick={() => {
              setOpenClass();
              setOpenDepartment();
            }}
          >
            <span className="breadcrumb-item__label">
              {grades.find((grd) => grd.grade_id == openGrade)
                ? grades.find((grd) => grd.grade_id == openGrade).grade_name
                : ""}
            </span>
            {openClass ? <span className="breadcrumb-item__icon">></span> : ""}
          </div>
        ) : (
          ""
        )}
        {openClass ? (
          <div
            className="breadcrumb-item"
            onClick={() => {
              setOpenClass();
              setOpenDepartment();
            }}
          >
            <span className="breadcrumb-item__label">
              {classes.find((cls) => cls.class_id == openClass)
                ? classes.find((cls) => cls.class_id == openClass).class_name
                : ""}
            </span>
            <span className="breadcrumb-item__icon">></span>
          </div>
        ) : (
          ""
        )}
        {openDepartment ? (
          <div
            className="breadcrumb-item"
            onClick={() => {
              setOpenDepartment();
            }}
          >
            <span className="breadcrumb-item__label">
              {departments.find((dep) => dep.department_id == openDepartment)
                ? departments.find((dep) => dep.department_id == openDepartment)
                    .department_name
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
        {/* <span
            onClick={() => {
              setOpenClass();
              setOpenGrade();
            }}
          >
            Πίσω
          </span> */}
      </div>
      <div className={"select-users__grades " + (openGrade ? " closed" : "")}>
        <div className={"select-users__grades-list select-users__list "}>
          <div
            onClick={() => handleSelectAll()}
            className={
              "select-users__item all grade "
              // (selectedGrades.includes(-1) ? "active" : "")
            }
          >
            <span>
              {grades.every((grd) => selectedGrades.includes(grd.grade_id))
                ? "Αποεπιλογή όλων"
                : "Επιλογή όλων"}
            </span>
          </div>
          {populateGrades()}
        </div>
      </div>
      <div
        className={
          "select-users__classes " +
          (openClass ? " closed-left" : "") +
          (!openGrade ? " closed-right" : "")
        }
      >
        <div className={"select-users__grades-list select-users__list "}>
          <div
            onClick={() => handleSelectAllClasses()}
            className={"select-users__item  all grade "}
          >
            <span>
              {classes
                .filter((cls) => cls.grade_id == openGrade)
                .every((cls) => selectedClasses.includes(cls.class_id))
                ? "Αποεπιλογή όλων"
                : "Επιλογή όλων"}
            </span>
          </div>
          {populateClasses()}
        </div>
      </div>
      <div
        className={
          "select-users__departments " +
          (openDepartment ? " closed-left" : "") +
          (!openClass ? " closed-right" : "")
        }
      >
        <div className={"select-users__grades-list select-users__list "}>
          <div
            onClick={() => handleSelectAllDepartments()}
            className={"select-users__item all grade "}
          >
            <span>
              {departments.every((dep) =>
                selectedDepartments.includes(dep.department_id)
              )
                ? "Αποεπιλογή όλων"
                : "Επιλογή όλων"}
            </span>
          </div>
          {populateDepartments()}
        </div>
      </div>
      <div
        className={
          "select-users__users " +
          (openUser ? " closed-left" : "") +
          (!openDepartment ? " closed-right" : "")
        }
      >
        <div className={"select-users__grades-list select-users__list "}>
          {/* <div
            onClick={() => handleSelectAllUsers()}
            className={
              "select-users__item user " +
              (selectedGrades.includes(-1) ? "active" : "")
            }
          >
            <span>Όλoι</span>
          </div> */}
          {populateUsers()}
        </div>
      </div>
    </div>
  );
}

export default SelectUsers;

import React from "react";
import "./toolbox-wrapper.scss";
import Inspector from "./inspector";
import { BiImageAlt, BiSpreadsheet, BiUser } from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import {
  BsAlignMiddle,
  BsAlignTop,
  BsAspectRatio,
  BsCardChecklist,
  BsCardText,
  BsClipboardData,
  BsFileBarGraph,
  BsHouse,
  BsLayoutTextSidebar,
} from "react-icons/bs";

const ToolboxWrapper = ({
  layout,
  editingComponentId,
  onUpdateItem,
  onStopEditing,
  onComponentDragStart, // new prop
}) => {
  // A function to handle drag start from the toolbox
  const handleDragStart = (e, componentType) => {
    e.dataTransfer.setData("componentType", componentType);
    // Inform the parent about the type being dragged.
    if (onComponentDragStart) onComponentDragStart(componentType);
  };

  // The item currently being edited (if any)
  const editingItem = layout.find((item) => item.i === editingComponentId);

  return (
    <div className="toolbox-wrapper no-scrollbar">
      {editingItem ? (
        <Inspector
          item={editingItem}
          onUpdateItem={onUpdateItem}
          onStopEditing={onStopEditing}
        />
      ) : (
        <div className="toolbox-wrapper__components">
          <div className="components-wrapper">
            <div className="components-wrapper__title">
              <span>Βασικά Στοιχεία</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "text")}
            >
              <BsCardText
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Κείμενο</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "image")}
            >
              <BiImageAlt
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Φωτογραφία</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "school-logo")}
            >
              <BsHouse
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Λογότυπο Φροντιστηρίου</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "spacer")}
            >
              <BsAspectRatio
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Κενό</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "line")}
            >
              <BsAlignMiddle
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Γραμμή</span>
            </div>
          </div>
          <div className="components-wrapper">
            <div className="components-wrapper__title">
              <span>Στοιχεία Μαθητή</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "user-image")}
            >
              <BiUser
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Φωτογραφία Μαθητή</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "user-full-name")}
            >
              <BsLayoutTextSidebar
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Ονοματεπώνυμο Μαθητή</span>
            </div>
          </div>
          <div className="components-wrapper">
            <div className="components-wrapper__title">
              <span>Στατιστικά Μαθητή</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "evaluation-stats")}
            >
              <BiSpreadsheet
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Στατιστικά Αξιλόγησης</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "exam-stats")}
            >
              <BiSpreadsheet
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Στατιστικά Διαγωνισμάτων</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "exam-graph")}
            >
              <BsFileBarGraph
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Γράφημα Διαγωνισμάτων</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "test-stats")}
            >
              <BsCardChecklist
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>
                Στατιστικά<br></br> Τεστ
              </span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "student-comments")}
            >
              <BiSpreadsheet
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Σχόλια Μαθητή</span>
            </div>
            <div
              className="toolbox-item"
              draggable
              onDragStart={(e) => handleDragStart(e, "evaluation-stats")}
            >
              <BsClipboardData
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Στατιστικά Αξιολόγησης</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolboxWrapper;

import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiAnalyse,
  BiBookOpen,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
  BiHome,
  BiPhone,
  BiSync,
  BiTime,
  BiUser,
  BiX,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { iconStyle } from "../../../../utils/generalUtils";
import "./professor-event-card.scss";
import ExamRecords from "../../../ExamCard/examRecords";
import { useSelector } from "react-redux";
import OnlineExamPreview from "./onlineExamPreview";
import DismissalUserItem from "../../../Calendar/dismissalUserItem";
import ChaptersTimeline from "../../ChaptersTimeline/chaptersTimeline";
import StudentComments from "./StudentComments/studentComments";
import { getGeneralSettingValue } from "../../../../utils/generalUtils";
import EventComment from "../../EventComment/eventComment";
import { Link } from "react-router-dom";

function ProfessorEventCard({
  event,
  closeCard,
  selectedDate,
  dismissalInitActive,
  showIframe,
  setShowIframe,
}) {
  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [students, setStudents] = useState([]);
  const [dismissals, setDismissals] = useState([]);

  const [parentEvents, setParentEvents] = useState([]);
  const [parentChildren, setParentChildren] = useState([]);
  const [eventParentData, setEventParentData] = useState();

  const [eventProfessors, setEventProfessors] = useState([]);
  const [eventClasses, setEventClasses] = useState([]);

  useEffect(() => {
    if (event.type == "lecture") {
      const cleanUpGetDismissals = getDismissals();
      const cleanUpGetStudentsInEvent = getStudentsInEvent();
      return () => {
        cleanUpGetDismissals();
        cleanUpGetStudentsInEvent();
      };
    }
  }, [event]);

  useEffect(() => {
    if (event.type == "parent-event") {
      const cleanUpgetParentEvents = getParentEvents();
      const cleanUpGetEventProfessors = getEventProfessors();
      const cleanUpGetEventClasses = getEventClasses();
      return () => {
        cleanUpgetParentEvents();
        cleanUpGetEventProfessors();
        cleanUpGetEventClasses();
      };
    }
  }, [event]);

  useEffect(() => {
    if (event.type == "parent-event-meeting") {
      const cleanUpGetParentChildren = getParentChildren();
      const cleanUpGetEventParentData = getEventParentData();
      return () => {
        cleanUpGetParentChildren();
        cleanUpGetEventParentData();
      };
    }
  }, [event]);

  const getEventClasses = () => {
    let args = {
      event_id: event.event_id,
    };

    const getEventClassesListener = (data) => {
      setEventClasses(data);
    };

    const refreshEventClassesListener = () => {
      socketContext.socket.emit("getEventClasses", args);
    };

    socketContext.socket.on("eventClasses", getEventClassesListener);
    socketContext.socket.emit("getEventClasses", args);
    socketContext.socket.on("refreshEventClasses", refreshEventClassesListener);

    return () => {
      socketContext.socket.off("getEventClasses", getEventClassesListener);
      socketContext.socket.off("eventClasses", getEventClassesListener);
      socketContext.socket.off(
        "refreshEventClasses",
        refreshEventClassesListener
      );
    };
  };

  const getEventProfessors = () => {
    let args = {
      event_id: event.event_id,
    };

    const getProfessorsInEventListener = (data) => {
      setEventProfessors(data);
    };

    const refreshEventProfessorsListener = () => {
      socketContext.socket.emit("getProfessorsInEvent", args);
    };

    socketContext.socket.on("professorsInEvent", getProfessorsInEventListener);
    socketContext.socket.emit("getProfessorsInEvent", args);
    socketContext.socket.on(
      "refreshProfessorsInEvent",
      refreshEventProfessorsListener
    );

    return () => {
      socketContext.socket.off(
        "getProfessorsInEvent",
        getProfessorsInEventListener
      );
      socketContext.socket.off(
        "professorsInEvent",
        getProfessorsInEventListener
      );
      socketContext.socket.off(
        "refreshProfessorsInEvent",
        refreshEventProfessorsListener
      );
    };
  };

  const getParentEvents = () => {
    let args = {
      parent_event_id: event.event_id,
    };

    const getParentEventsListener = (data) => {
      const adjustedData = data.map((event) => {
        return {
          ...event,
          start_at: new Date(event.start_at), // Convert to Date object
          finish_at: new Date(event.finish_at), // Convert to Date object
        };
      });
      setParentEvents(adjustedData);
    };

    const refreshParentEventsListener = () => {
      socketContext.socket.emit("getParentEvents", args);
    };

    socketContext.socket.on("parentEvents", getParentEventsListener);
    socketContext.socket.emit("getParentEvents", args);
    socketContext.socket.on("refreshParentEvents", refreshParentEventsListener);

    return () => {
      socketContext.socket.off("getParentEvents", getParentEventsListener);
      socketContext.socket.off("parentEvents", getParentEventsListener);
      socketContext.socket.off(
        "refreshParentEvents",
        refreshParentEventsListener
      );
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    if (event.classroom_names)
      return event.classroom_names.map((classroom, index) => {
        return (
          <span key={"classroomName" + index}>
            {classroom} {index != event.classroom_names.length - 1 ? "," : ""}{" "}
            <span className="spacer"></span>
          </span>
        );
      });
  };

  const populateProfessors = () => {
    if (event.professor_names) {
      return event.professor_names.map((professor, index) => {
        const [firstName, lastName] = professor.split(" ");
        const formattedName = `${firstName} ${lastName.charAt(0)}.`;

        return (
          <span key={"professorName" + index}>
            {formattedName}
            {index !== event.professor_names.length - 1 ? "," : ""}
            <span className="spacer"></span>
          </span>
        );
      });
    }
  };

  const getEventParentData = () => {
    const getUsersListener = (data) => {
      if (data.length > 0) {
        setEventParentData(data[0]);
      } else {
        setEventParentData();
      }
    };

    socketContext.socket.on(
      "allUsersWithParams" + event.parent_id,
      getUsersListener
    );

    const args = { user_id: event.parent_id };
    socketContext.socket.emit("getAllUsersWithParams", args);

    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    return () => {
      socketContext.socket.off(
        "allUsersWithParams" + event.parent_id,
        getUsersListener
      );
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const getParentChildren = () => {
    let profId = null;
    try {
      profId = JSON.parse(event.professors)[0];
    } catch (e) {
      console.log(e);
    }
    let args = {
      parent_user_id: event.parent_id,
      professor_id: profId,
    };

    const getChildrenListener = (data) => {
      setParentChildren(data);
    };

    const refreshChildrenListener = () => {
      socketContext.socket.emit("getChildren", args);
    };

    socketContext.socket.on("children" + profId, getChildrenListener);
    socketContext.socket.emit("getChildren", args);
    socketContext.socket.on("refreshChildren", refreshChildrenListener);

    return () => {
      socketContext.socket.off("getChildren", getChildrenListener);
      socketContext.socket.off("children" + profId, getChildrenListener);
      socketContext.socket.off("refreshChildren", refreshChildrenListener);
    };
  };

  const getStudentsInEvent = () => {
    let args = { event_id: event.event_id };

    const getStudentsInEventListener = (data) => {
      setStudents(data);
    };

    const refreshStudentsInEventListener = () => {
      socketContext.socket.emit("getDismissals", args);
    };

    socketContext.socket.on("studentsInEvent", getStudentsInEventListener);
    socketContext.socket.emit("getStudentsInEvent", args);
    socketContext.socket.on(
      "refreshStudentsInEvent",
      refreshStudentsInEventListener
    );

    return () => {
      socketContext.socket.off(
        "getStudentsInEvent",
        getStudentsInEventListener
      );
      socketContext.socket.off("studentsInEvent", getStudentsInEventListener);
      socketContext.socket.off(
        "refreshStudentsInEvent",
        refreshStudentsInEventListener
      );
    };
  };

  const getDismissals = () => {
    let args = { event_id: event.event_id, date_added: selectedDate };

    const getDismissalsListener = (data) => {
      setDismissals(data);
    };

    const refreshDismissalsListener = () => {
      socketContext.socket.emit("getDismissals", args);
    };

    socketContext.socket.on(
      "dismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      getDismissalsListener
    );
    socketContext.socket.emit("getDismissals", args);
    socketContext.socket.on(
      "refreshDismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      refreshDismissalsListener
    );

    return () => {
      socketContext.socket.off("getDismissals", getDismissalsListener);
      socketContext.socket.off(
        "dismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        getDismissalsListener
      );
      socketContext.socket.off(
        "refreshDismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        refreshDismissalsListener
      );
    };
  };

  const updateDismissals = (user, dismissal) => {
    setDismissals((prevDismissals) =>
      prevDismissals.map((dis) =>
        dis.user_id === user.user_id ? { ...dis, dismissal: dismissal } : dis
      )
    );

    const dismissalDate = selectedDate;

    const body = {
      user_id: user.user_id,
      dismissal: dismissal,
      event_id: event.event_id,
      selected_date: dismissalDate,
    };
    socketContext.socket.emit("updateDismissals", body);
  };

  const populateStudents = (isCheckedIn) => {
    return students.map((student) => {
      if (
        isCheckedIn &&
        (dismissals.some(
          (obj) => obj.user_id === student.user_id && obj.dismissal === 1
        ) ||
          (dismissalInitActive &&
            !dismissals.some((obj) => obj.user_id === student.user_id)))
      ) {
        return (
          <DismissalUserItem
            key={"checkedinlUser " + student.user_id}
            type="remove"
            user={student}
            updateDismissals={updateDismissals}
          />
        );
      }
      if (
        !isCheckedIn &&
        (dismissals.some(
          (obj) => obj.user_id === student.user_id && obj.dismissal === 0
        ) ||
          (!dismissalInitActive &&
            !dismissals.some((obj) => obj.user_id === student.user_id)))
      ) {
        return (
          <DismissalUserItem
            key={"dismissallUser " + student.user_id}
            type="add"
            user={student}
            updateDismissals={updateDismissals}
          />
        );
      }
    });
  };

  const startCall = () => {
    if (!event.call_link) {
      const hash = window.crypto.randomUUID();
      const meeting_url = "https://meet.jit.si/" + hash;
      let eventBody = {
        meeting_url: meeting_url,
        event_id: event.event_id,
      };

      socketContext.socket.emit("addEventMeeting", eventBody);
    } else {
      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };

      const isAndroidWebView = () => {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        return /Android/.test(userAgent) && /wv/.test(userAgent);
      };

      let callLink = event.call_link;

      if (isRunningInWebView() && window.ReactNativeWebView) {
        if (isAndroidWebView()) {
          const opened = window.open(callLink, "_system");
          if (!opened) {
            // Fallback to changing the location
            window.location.href = callLink;
          }
        } else {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "OPEN_LINK",
              data: callLink,
            })
          );
        }
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  const populateParentEvents = () => {
    return parentEvents.map((ev) => {
      return <div className="scheduled-parent"></div>;
    });
  };

  const greekDays = [
    "Δευτέρα",
    "Τρίτη",
    "Τετάρτη",
    "Πέμπτη",
    "Παρασκευή",
    "Σάββατο",
    "Κυριακή",
  ];
  const populateEventProfessors = () => {
    return eventProfessors.map((prof) => {
      return (
        <Link
          to={"/profile?user-id=" + prof.user_id}
          key={"professor item " + prof.user_id}
          className="professor-item"
        >
          <div className="professor-item__personal">
            <img
              className="profile-img__img"
              src={
                prof.profile_picture
                  ? prof.profile_picture
                  : "resources/student.png"
              }
              alt={"student"}
            />
            <span className="profile-name">
              <span>{prof.first_name}</span>
              <span>{prof.last_name}</span>
            </span>
          </div>
          <div className="professor-item__availability">
            <span className="availability-label">Διαθεσιμότητα Καθηγητή</span>
            {prof.availability.map((av) => {
              return (
                <div className="av-item">
                  <span className="av-day">{greekDays[av.day - 1]}</span>
                  <div className="av-time">
                    <span>{av.start_time}</span>
                    <span>-</span>
                    <span>{av.end_time}</span>
                  </div>
                </div>
              );
            })}
            {!prof.availability.length ? (
              <span className="full-availability">Πλήρης Διαθεσιμότητα</span>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    });
  };

  const populateEventClasses = () => {
    return eventClasses.map((cls, index) => {
      let clsName = cls;
      if (index <= eventClasses.length - 1) {
        // clsName += ", ";
      }
      return (
        <div className="class-item">
          <span>{clsName}</span>
        </div>
      );
    });
  };

  const populateParentChildren = () => {
    return parentChildren.map((child) => {
      let classes = [];
      // try {
      //   classes = JSON.parse(child.common_classes);
      // } catch (e) {
      //   console.log(e);
      // }
      return (
        <Link
          to={"/profile?user-id=" + child.user_id}
          key={"parent-child-event " + child.user_id}
          className="student-side__list-item"
        >
          <div className="child-personal">
            <img
              className="profile-img__img"
              src={
                child.profile_picture
                  ? child.profile_picture
                  : "resources/student.png"
              }
              alt={"student"}
            />
            <div className="child-personal__name">
              <span>{child.first_name}</span>
              <span>{child.last_name}</span>
            </div>
            {child.common_classes ? (
              <div className="child-common">
                <span className="child-common__title">Κοινά Μαθήματα:</span>
                <div className="child-common__list">
                  {child.common_classes.map((cls) => {
                    return <span>{cls}</span>;
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    });
  };

  return (
    <div className="professor-event-card">
      <div className="info">
        <div
          className="professor-event-card__close"
          onClick={() => closeCard()}
        >
          <BiX
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
        </div>
        <span className="info__title">
          <span className="label">{event.title}</span>
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendarCheck
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {event.type == "lecture"
                    ? formatDate(selectedDate)
                    : formatDate(event.start_at)}
                  {event.type == "online-exam"
                    ? " στις " + formatTime(event.start_at)
                    : ""}
                </span>
              </div>
            </div>
            {event.type == "online-exam" ? (
              <div className="item">
                <BiCalendarX
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="date">
                    {event.type == "online-exam"
                      ? formatDate(event.finish_at) +
                        " στις " +
                        formatTime(event.finish_at)
                      : ""}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="item">
              <BiTime
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="time">
                  {event.type != "online-exam"
                    ? formatTime(event.start_at) +
                      " - " +
                      formatTime(event.finish_at)
                    : "Διάρκεια: " + event.duration + " λεπτά"}
                </span>
              </div>
            </div>
            {event.for_phone ? (
              <div className="item">
                <BiPhone
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="time">Τηλεφωνική Ενημέρωση</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {event.type != "parent-event" &&
            event.type != "parent-event-meeting" ? (
              <div className="item">
                <BiBookOpen
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="label">
                    {event.type == "lecture" ? "Μάθημα" : ""}
                    {event.type == "exam" ? "Διαγώνισμα" : ""}
                    {event.type == "online-exam"
                      ? "Ηλεκτρονικό Διαγώνισμα"
                      : ""}
                    {event.type == "test" ? "Τεστ" : ""}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {event.repeat_type == "weekly" ? (
              <div className="item">
                <BiSync
                  size={"33px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="label">Εβδομαδιαία Επανάληψη</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {event.type == "exam" ||
            (event.type == "test" && event.classroom_names) ? (
              <div className="item">
                <BiHome
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateClassrooms()}</div>
              </div>
            ) : (
              ""
            )}
            {(event.type == "exam" ||
              event.type == "test" ||
              event.type == "online-exam") &&
            event.classroom_names ? (
              <div className="item">
                <BiUser
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateProfessors()}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {!getGeneralSettingValue(generalSettings, "lecture-general-comment") &&
      event.type == "lecture" ? (
        <ChaptersTimeline selectedDate={selectedDate} event={event} />
      ) : getGeneralSettingValue(generalSettings, "lecture-general-comment") &&
        event.type == "lecture" ? (
        <EventComment selectedDate={selectedDate} event={event} />
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <button className="cta start-online mobile" onClick={() => startCall()}>
          <BiDesktop
            size={"25px"}
            color={"#6225e6"}
            style={iconStyle("transparent")}
          />
          <span>
            {!event.call_link
              ? "Μετατροπή σε ηλεκτρονικό μάθημα"
              : "Εκκίνηση ηλεκτρονικού μαθήματος"}
          </span>
        </button>
      ) : (
        ""
      )}
      {event.type == "parent-event-meeting" && eventParentData ? (
        <div className="parent-event-detailed">
          <div className="parent-side">
            <span className="parent-side__title">Γονέας</span>
            <Link
              to={"/profile?user-id=" + eventParentData.user_id}
              className="parent-detailed"
            >
              <img
                className="profile-img__img"
                src={
                  eventParentData.profile_picture
                    ? eventParentData.profile_picture
                    : "resources/student.png"
                }
                alt={"student"}
              />
              <div className="parent-detailed__name">
                <span>{eventParentData.first_name}</span>
                <span>{eventParentData.last_name}</span>
              </div>
              {eventParentData.personal_phone || eventParentData.house_phone ? (
                <div className="parent-detailed__line"></div>
              ) : (
                ""
              )}
              {eventParentData.personal_phone &&
              eventParentData.personal_phone != "nan" ? (
                <div className="parent-detailed__info">
                  <span className="parent-detailed__info-label">
                    Κινητό Τηλέφωνο
                  </span>
                  <span>{eventParentData.personal_phone}</span>
                </div>
              ) : (
                ""
              )}
              {eventParentData.house_phone &&
              eventParentData.house_phone != "nan" ? (
                <div className="parent-detailed__info">
                  <span className="parent-detailed__info-label">Σταθερό</span>
                  <span>
                    {eventParentData.house_phone != "nan"
                      ? eventParentData.house_phone
                      : ""}
                  </span>
                </div>
              ) : (
                ""
              )}
            </Link>
          </div>
          <div className="student-side">
            <span className="student-side__title">Μαθητές Γονέα</span>
            <div className="student-side__list">{populateParentChildren()}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      {event.type == "parent-event-meeting" && event.call_link ? (
        <button className="cta start-online " onClick={() => startCall()}>
          <BiDesktop
            size={"25px"}
            color={"#6225e6"}
            style={iconStyle("transparent")}
          />
          <span>Εκκίνηση ηλεκτρονικής σύσκεψης</span>
        </button>
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <motion.div className="dismissal">
          <div className="dismissal-users">
            <div className="wrapper">
              <div className="title">Παρών</div>
              <div className="users-list">
                {!dismissals ||
                !dismissals.length ||
                dismissals.find((dis) => dis.dismissal) ||
                dismissals.length != students.length ? (
                  ""
                ) : (
                  <span className="users-list__label">
                    Όλοι οι μαθητές είναι απόντες...
                  </span>
                )}
                <AnimatePresence>{populateStudents(true)}</AnimatePresence>
              </div>
            </div>
            <div className="wrapper dismissed-users">
              <div className="title">Απών</div>
              <div className="users-list">
                {dismissals.find((dis) => !dis.dismissal) ? (
                  ""
                ) : (
                  <span className="users-list__label">
                    Όλοι οι μαθητές είναι παρόντες...
                  </span>
                )}
                <AnimatePresence>{populateStudents(false)}</AnimatePresence>
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        ""
      )}
      {event.type == "parent-event" ? (
        <div className="event-parents-classes">
          <span className="event-parents-classes__title">
            Επιλγμένα Μαθήματα
          </span>
          <div className="event-parents-classes__list">
            {populateEventClasses()}
          </div>
        </div>
      ) : (
        ""
      )}
      {event.type == "parent-event" ? (
        <div className="event-parents-professors">
          <span className="event-parents-professors__title">
            Καθηγητές Ενημέρωσης
          </span>
          <div className="event-parents-professors__list">
            {populateEventProfessors()}
          </div>
        </div>
      ) : (
        ""
      )}
      {event.type == "parent-event" ? (
        <div className="event-parents-scheduled">
          {parentEvents && parentEvents.length ? (
            <div className="event-parents-scheduled__list">
              {populateParentEvents()}
            </div>
          ) : (
            <div className="event-parents-scheduled__none">
              <span>Κανένας γονέας δεν έχει προγραμμάτισει ραντέβου</span>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <StudentComments
          event={event}
          students={students}
          selectedDate={selectedDate}
        />
      ) : (
        ""
      )}
      {event.type == "exam" || event.type == "test" ? (
        <div className="marking general">
          <ExamRecords exam={event} selectedDate={selectedDate} />
        </div>
      ) : (
        ""
      )}
      {event.type == "online-exam" ? <OnlineExamPreview event={event} /> : ""}
    </div>
  );
}

export default ProfessorEventCard;

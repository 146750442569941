import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAlignMiddle,
  BiPencil,
  BiPlus,
  BiPrinter,
  BiUpload,
  BiUserPin,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./print-grade-item.scss";
import { useReactToPrint } from "react-to-print";

function PrintGradeItem() {
  const htmlRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      let newPositionStyle = {
        top: window.innerHeight * 0.5 - 225 - topPosition + "px",
        left: window.innerWidth * 0.3 - leftPosition + "px",
        width: "40vw",
        height: "450px",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  return (
    <div
      ref={containerRef}
      className={
        "print-grade-item " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "print-grade-item__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="print-grade-item__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="config-title">
              <span className="config-title__label">Τίτλος</span>
              {/* <input
                className="input"
                placeholder="Εισάγεται τίτλο..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              /> */}
            </div>
            <div className="config-type">
              <span className="config-type__label">Τύπος template</span>
            </div>
            <div
              className="print-grade-item__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="print-grade-item__preview">
            <BiPlus
              size={"30px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default PrintGradeItem;

import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Compress from "compress.js";
import { nanoid } from "nanoid";
import { tokenUtils } from "../../../utils/token-utils";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          resolve({
            default: URL.createObjectURL(file), // Create a temporary URL for local file
          });
        })
    );
  }
  abort() {
    console.log("Upload aborted");
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

const InspectorTextEditor = ({
  text,
  setText, // parent's callback for real-time updates
  imagesEndpoint,
  postImagesEndPoint,
  fileNameStart,
  saveFiles,
}) => {
  const [editorData, setEditorData] = useState(text || "");

  // If the parent passes in a new 'text', sync it to local editor data
  useEffect(() => {
    if (text !== undefined && text !== editorData) {
      setEditorData(text);
    }
  }, [text]);

  // If saveFiles is triggered externally, run handleSave
  useEffect(() => {
    if (saveFiles) {
      handleSave();
    }
  }, [saveFiles]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    // 1) Update local state for CKEditor UI
    setEditorData(data);
    // 2) Also notify parent in real-time
    setText(data);
  };

  async function compressAndUploadImages(images) {
    const compress = new Compress();
    const files = [];
    for (const image of images) {
      const response = await fetch(image.src);
      const blob = await response.blob();

      const compressedImages = await compress.compress([blob], {
        size: 2,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      });

      const { data, ext } = compressedImages[0];
      const compressedBlob = Compress.convertBase64ToFile(data, ext);

      let fileExtension;
      if (blob.type === "image/jpeg") fileExtension = ".jpg";
      if (blob.type === "image/png") fileExtension = ".png";

      let fileName = fileNameStart + "-" + nanoid() + fileExtension;
      const newImageSrc = `resources/${imagesEndpoint}/${fileName}`;

      const newFile = new File([compressedBlob], fileName, {
        type: compressedBlob.type,
        lastModified: new Date().getTime(),
      });

      image.src = newImageSrc;
      files.push(newFile);
    }

    const config = {
      headers: { Authorization: tokenUtils.getBearerToken() },
    };
    const uploadedImages = [];

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("arrayOfFilesName", file);
      });

      try {
        const response = await axios.post(postImagesEndPoint, formData, config);
        if (response.data && response.data.path) {
          files.forEach((_, index) => {
            const baseUrl = "http://localhost:3001/";
            const newImageSrc = `${baseUrl}resources/${imagesEndpoint}/${response.data.path[index]}`;
            uploadedImages.push({
              newSrc: newImageSrc,
            });
          });
        }
      } catch (ex) {
        console.log(ex);
      }
    }

    setEditorData(""); // optional if you want to reset
    return uploadedImages;
  }

  const onSave = async (htmlData) => {
    // parse doc
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, "text/html");
    const images = Array.from(doc.querySelectorAll("img"));

    const uploadedImages = await compressAndUploadImages(images);

    // replace src for images in doc
    uploadedImages.forEach(({ newSrc }, idx) => {
      const imgEl = images[idx];
      if (imgEl) {
        imgEl.src = newSrc;
      }
    });

    const serializer = new XMLSerializer();
    const updatedHtml = serializer.serializeToString(doc);

    // optional baseUrl rewriting, etc.
    // ...
    // Finally, set parent's text with final html
    setText(updatedHtml);
  };

  const handleSave = async () => {
    await onSave(editorData);
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "insertTable",
              "|",
              "uploadImage",
              "blockQuote",
              "|",
              "undo",
              "redo",
            ],
            shouldNotGroupWhenFull: true,
          },
        }}
      />
    </div>
  );
};

export default InspectorTextEditor;

import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAlignMiddle,
  BiBookOpen,
  BiCalendarWeek,
  BiPencil,
  BiPlus,
  BiPrinter,
  BiUpload,
  BiUser,
  BiUserPin,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import "./template-item.scss";
import { useReactToPrint } from "react-to-print";
import TemplatePreview from "../TemplatePreview/templatePreview";
import { Link } from "react-router-dom";
import LayoutRenderer from "../../TemplateEditor/layoutRenderer";
import ButtonMultipleToggle from "../../../components/ButtonMultipleToggle/buttonMultipleToggle";
import PrintSection from "./PrintSection/printSection";
import {
  PrintSectionProvider,
  usePrintSectionContext,
} from "./PrintSection/printSectionContext";

function TemplateItem({ templateData, templateTitle, templateId }) {
  const htmlRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const {
    grades,
    selectedGrades,
    setSelectedGrades,
    classes,
    selectedClasses,
    setSelectedClasses,
    departments,
    selectedDepartments,
    setSelectedDepartments,
    users,
    selectedUsers,
    setSelectedUsers,
    departmentHasUsers,
    handleGradeClick,
  } = usePrintSectionContext();

  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [exams, setExams] = useState([]);
  const [comments, setComments] = useState([]);
  const [evaluationSettings, setEvaluationSettings] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [ecaluationData, setEvaluationData] = useState([]);
  const [testData, setTestData] = useState([]);

  const [toggleTypeNum, setToggleTypeNum] = useState(1);
  const [toggleType, setToggleType] = useState("Εκτύπωση");

  const openCard = () => {
    setIsExpanded(true);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      let newPositionStyle = {
        // top: window.innerHeight * 0.5 - 225 - topPosition + "px",
        top: window.innerHeight * 0.05 - topPosition + "px",
        left: window.innerWidth * 0.05 - leftPosition + "px",
        width: "90vw",
        height: "90vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (
      templateData &&
      JSON.stringify(templateData).includes('"type":"exam-stats"') &&
      users
    ) {
      const cleanUpGetExamStats = getExamStats();
      return () => {
        cleanUpGetExamStats();
      };
    }
  }, [users, templateData]);

  useEffect(() => {
    if (
      templateData &&
      JSON.stringify(templateData).includes('"type":"student-comments"') &&
      users
    ) {
      const cleanUpGetComments = getComments();
      return () => {
        cleanUpGetComments();
      };
    }
  }, [users, templateData]);

  useEffect(() => {
    if (
      templateData &&
      JSON.stringify(templateData).includes('"type":"evaluation-stats"') &&
      users
    ) {
      const cleanUpGetEvaluationSettings = getEvaluationSettings();
      const cleanUpGetEvaluation = getEvaluation();
      return () => {
        cleanUpGetEvaluation;
        cleanUpGetEvaluationSettings();
      };
    }
  }, [users, templateData]);

  const getEvaluationSettings = () => {
    let args = {};
    const getEvaluationSettingsListener = (data) => {
      setEvaluationSettings(data);
    };

    socketContext.socket.on(
      "evaluationSettings",
      getEvaluationSettingsListener
    );
    socketContext.socket.emit("getEvaluationSettings", args);

    return () => {
      socketContext.socket.off(
        "getEvaluationSettings",
        getEvaluationSettingsListener
      );
      socketContext.socket.off(
        "evaluationSettings",
        getEvaluationSettingsListener
      );
    };
  };

  const getEvaluation = () => {
    const userIds = users.map((user) => user.user_id);
    let args = { selected_users: userIds };

    const getPrintEvaluationListener = (data) => {
      setEvaluation(data);
    };

    socketContext.socket.on("printEvaluation", getPrintEvaluationListener);
    socketContext.socket.emit("getPrintEvaluation", args);

    return () => {
      socketContext.socket.off(
        "getPrintEvaluation",
        getPrintEvaluationListener
      );
      socketContext.socket.off("printEvaluation", getPrintEvaluationListener);
    };
  };

  const getExamStats = () => {
    const userIds = users.map((user) => user.user_id);
    let args = { selected_users: userIds };

    const getExamsListener = (data) => {
      setExams(data);
    };

    socketContext.socket.on("printExams", getExamsListener);
    socketContext.socket.emit("getPrintExams", args);

    return () => {
      socketContext.socket.off("getPrintExams", getExamsListener);
      socketContext.socket.off("printExams", getExamsListener);
    };
  };

  const getComments = () => {
    const userIds = users.map((user) => user.user_id);
    let args = { selected_users: userIds };

    const getCommentsListener = (data) => {
      setComments(data);
    };

    socketContext.socket.on("printComments", getCommentsListener);
    socketContext.socket.emit("getPrintComments", args);

    return () => {
      socketContext.socket.off("getPrintComments", getCommentsListener);
      socketContext.socket.off("printComments", getCommentsListener);
    };
  };

  useEffect(() => {
    const uniqueGrades = [...new Set(selectedGrades)];

    if (uniqueGrades.length !== selectedGrades.length) {
      setSelectedGrades(uniqueGrades);
    }
  }, [selectedGrades]);

  useEffect(() => {
    const uniqueClasses = [...new Set(selectedClasses)];

    if (uniqueClasses.length !== selectedClasses.length) {
      setSelectedClasses(uniqueClasses);
    }
  }, [selectedClasses]);

  useEffect(() => {
    const uniqueDepartments = [...new Set(selectedDepartments)];

    if (uniqueDepartments.length !== selectedDepartments.length) {
      setSelectedDepartments(uniqueDepartments);
    }
  }, [selectedDepartments]);

  useEffect(() => {
    const uniqueUsers = [...new Set(selectedUsers)];

    if (uniqueUsers.length !== selectedUsers.length) {
      setSelectedUsers(uniqueUsers);
    }
  }, [selectedUsers]);

  const populateSelectedGrades = () => {
    if (!grades.every((grd) => selectedGrades.includes(grd.grade_id))) {
      return selectedGrades.map((grd) => {
        const gradeItem = grades.find((grade) => grade.grade_id == grd);
        if (gradeItem) {
          return (
            <div
              key={"grade item " + gradeItem.grade_id}
              className="footer__selected-list-item"
            >
              <div className="list-item__close">
                <BiX
                  size={"25px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                  onClick={() => handleGradeClick(grd.grade_id)}
                />
              </div>
              <BiCalendarWeek
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
              <span>{gradeItem.grade_name}</span>
            </div>
          );
        }
      });
    } else {
      ("Όλες οι τάξεις");
    }
  };

  const populateSelectedClasses = () => {
    return selectedClasses.map((cls) => {
      const classItem = classes.find((clsItem) => clsItem.class_id == cls);
      if (
        classItem &&
        !selectedGrades.some((grd) => grd == classItem.grade_id)
      ) {
        return (
          <div
            key={"class item " + classItem.class_id}
            className="footer__selected-list-item"
          >
            <BiBookOpen
              size={"25px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
            />
            <span>{classItem.class_name}</span>
          </div>
        );
      }
    });
  };

  const populateSelectedDepartments = () => {
    return selectedDepartments.map((dep) => {
      const departmentItem = departments.find(
        (department) => department.department_id == dep
      );
      if (departmentItem) {
        const hasGradeWithDep = () => {
          const classWithDep = classes.find(
            (cls) => cls.class_id == departmentItem.class_id
          );
          if (selectedClasses.some((cls) => cls == classWithDep.class_id)) {
            return true;
          }
          const gradeWithDep = grades.find(
            (grd) => grd.grade_id == classWithDep.grade_id
          );
          if (selectedGrades.some((grd) => grd == gradeWithDep.grade_id)) {
            return true;
          }
          return false;
        };
        if (!hasGradeWithDep()) {
          return (
            <div
              key={"department item " + departmentItem.department_id}
              className="footer__selected-list-item"
            >
              <BiUser
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
              <span>{departmentItem.department_name}</span>
            </div>
          );
        }
      }
    });
  };

  const populateSelectedUsers = () => {
    return selectedUsers.map((usr) => {
      const userItem = users.find((user) => user.user_id == usr);
      if (userItem) {
        const hasGradeWithUser = () => {
          const departmentsWithUser = departments.filter((dep) =>
            departmentHasUsers.some(
              (dep_users) =>
                dep_users.user_id == userItem.user_id &&
                dep_users.department_id == dep.department_id
            )
          );

          if (
            departmentsWithUser.some((dep) => selectedDepartments.includes(dep))
          ) {
            return true;
          }

          if (
            selectedClasses.some((cls) =>
              departmentsWithUser.some((dep) => dep.class_id == cls)
            )
          ) {
            return true;
          }

          if (
            selectedGrades.some((grd) =>
              departmentsWithUser.some(
                (dep) =>
                  classes.find((cls) => cls.class_id == dep.class_id)
                    .grade_id == grd
              )
            )
          ) {
            return true;
          }

          return false;
        };

        if (!hasGradeWithUser()) {
          return (
            <div
              key={"user item " + userItem.user_id}
              className="footer__selected-list-item"
            >
              <BiBookOpen
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
              <span>{userItem.user_name}</span>
            </div>
          );
        }
      }
    });
  };

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Reference to the element you want to print
    documentTitle: "Student Report", // Optional: Title for the print document
  });

  return (
    <div
      ref={containerRef}
      className={
        "template-item " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "template-item__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        <div
          className={
            "template-box no-scrollbar " + (isExpanded ? "expanded" : "")
          }
          ref={printRef}
        >
          {isExpanded
            ? users.map((user) => (
                <LayoutRenderer
                  key={"layout renderer " + user.user_id}
                  layoutData={templateData}
                  isEditable={false}
                  containerWidth={700}
                  scaleDown={!isExpanded}
                  printMode={isExpanded}
                  user={user}
                  exams={exams[user.user_id] || []}
                  comments={comments[user.user_id] || []}
                  evaluationSettings={evaluationSettings}
                  evaluation={evaluation[user.user_id] || []}
                />
              ))
            : ""}
          {!isExpanded ? (
            <LayoutRenderer
              layoutData={templateData}
              isEditable={false}
              containerWidth={700}
              scaleDown={isExpanded ? false : true}
              printMode={isExpanded ? true : false}
              exams={exams}
            />
          ) : (
            ""
          )}
          {isExpanded ? (
            <div className="template-box__actions">
              <Link
                to={"/print/editor?template-id=" + templateId}
                className="template-box__actions-item edit"
              >
                <BiPencil
                  size={"25px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                />
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
        {!isExpanded ? (
          <div className="overlay__title">
            <span>{templateTitle}</span>
          </div>
        ) : (
          ""
        )}
        {isExpanded ? (
          <div className="template-item__configure">
            <div className="configure-container">
              <ButtonMultipleToggle
                button1="Εκτύπωση"
                button2="Email"
                button3="Προγραμματισμός"
                setSelected={setToggleType}
                selected={toggleTypeNum}
              ></ButtonMultipleToggle>
              {toggleType == "Εκτύπωση" ? <PrintSection /> : ""}
            </div>
            <div className="configure-footer">
              <div className="footer__selected">
                <div className="footer__selected-list">
                  {populateSelectedGrades()}
                  {populateSelectedClasses()}
                  {populateSelectedDepartments()}
                  {populateSelectedUsers()}
                </div>
              </div>
              <div className="template-item__actions">
                <button className="cta" onClick={() => handlePrint()}>
                  <BiPrinter
                    size={"25px"}
                    color={"#ccc"}
                    style={iconStyle("transparent")}
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
}

export default TemplateItem;

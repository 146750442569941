import React, { useState, useEffect, useRef, useContext } from "react";
import { BiArrowBack, BiSave } from "react-icons/bi";
import "./configure-event.scss";
import GeneralSection from "./sections/GeneralSection/generalSection";
import Slider from "react-slick";
import DateSection from "./sections/DateSection/dateSection";
import GradesSection from "./sections/GradesSection/gradesSection";
import { useEventContext } from "./eventContext";
import { SocketContext } from "../../app/socket";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OnlineGradesSection from "./sections/OnlineGradesSection/onlineGradesSection";
import OnlineDateSection from "./sections/OnlineDateSection/onlineDateSection";
import { useSelector } from "react-redux";
import AnnouncementSection from "./sections/AnnouncementSection/announcementSection";

function ConfigureEvent({
  setGotType,
  showCreateEvent,
  setShowCreateEvent,
  setConfigureSlide,
  configureSlide,
  isEdit,
  events,
  setEvents,
  showLecture,
  selectedDate,
}) {
  const socketContext = useContext(SocketContext);
  const profile = useSelector((state) => state.profile.value);

  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const navigate = useNavigate();
  const { eventType, setEventType, announcementId } = useEventContext();
  const { errorMsg, eventOld } = useEventContext();
  // const [eventType, setEventType] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  const { saveRichText, setSaveRichText } = useEventContext();
  const { saveAnnouncementText, setSaveAnnouncementText } = useEventContext();

  const { onlineGrades, setOnlineGrades } = useEventContext();

  const { eventTitle, announcementTitle } = useEventContext();
  const { eventDuration } = useEventContext();
  const { eventId } = useEventContext();
  const { eventClass } = useEventContext();
  const { eventDepartments } = useEventContext();
  const { eventStartDate } = useEventContext();
  const { eventEndDate } = useEventContext();
  const { eventProfessors } = useEventContext();
  const { eventClassrooms } = useEventContext();
  const { eventClassroomName } = useEventContext();
  const { eventRepeatType } = useEventContext();
  const { eventMaxRank } = useEventContext();
  const { eventDetailedRank, eventDetailedNames } = useEventContext();
  const { eventExamId } = useEventContext();

  const { noErrors, setNoErrors } = useEventContext();
  const { setTestForErrors } = useEventContext();
  const { setFinalTestForErrors } = useEventContext();
  const { noFinalErrors, setNoFinalErrors } = useEventContext();

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef();

  const settings = {
    infinite: false,
    speed: 500,
    draggable: false,
    swipe: false, // Disable swipe for touch devices
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  useEffect(() => {
    if (noErrors) {
      nextSlide();
    }
  }, [noErrors]);

  const tryNextSlide = () => {
    setTestForErrors(true);
  };

  const nextSlide = (type) => {
    setNoErrors(false);
    setTestForErrors(false);
    if (currentSlide === 0) {
      if (isEdit) {
        if (
          eventType === "lecture" ||
          eventType == "announcement" ||
          eventType == "parent-event"
        ) {
          setConfigureSlide("date");
        } else {
          if (eventType != "online-exam") {
            setConfigureSlide("grades");
          } else {
            setConfigureSlide("online-grades");
          }
        }
      } else {
        setConfigureSlide("general");
      }
      if (!isEdit) {
        setEventType(null);
      }
    } else if (currentSlide === 1) {
      if (isEdit) {
        setCurrentSlide(2);
        if (eventType == "online-exam") {
          setConfigureSlide("online-date");
        } else {
          setConfigureSlide("date");
        }
      } else {
        if (eventType === "lecture") {
          setConfigureSlide("date");
        } else if (eventType == "announcement") {
          setConfigureSlide("online-date");
        } else if (eventType == "parent-event") {
          setConfigureSlide("online-date");
        } else {
          if (eventType != "online-exam") {
            setConfigureSlide("grades");
          } else {
            setConfigureSlide("online-grades");
          }
        }
        setCurrentSlide(2);
      }
    } else if (currentSlide === 2) {
      setCurrentSlide(3);
      if (eventType == "online-exam") {
        setConfigureSlide("online-date");
      } else {
        setConfigureSlide("date");
      }
    }

    if (currentSlide >= 0 && setGotType) {
      setGotType(true);
    }
    if (currentSlide === 0) {
      setCurrentSlide(1);
    }
    sliderRef.current.slickNext();
    if (type) {
      if (!isEdit) {
        setEventType(type);
      }
    }
  };

  const prevSlide = () => {
    if (currentSlide === 1 || (eventType && currentSlide === 1)) {
      if (isEdit) {
        setConfigureSlide("general");
      } else {
        setConfigureSlide("type");
        setEventType("");
      }
      setCurrentSlide(0);
    } else if (currentSlide === 2) {
      setCurrentSlide(1);
      if (isEdit) {
        if (eventType === "lecture" || eventType == "parent-event") {
          setConfigureSlide("general");
        } else {
          if (eventType != "online-exam") {
            setConfigureSlide("grades");
          } else {
            setConfigureSlide("online-grades");
          }
        }
      } else {
        setConfigureSlide("general");
      }
    } else if (currentSlide === 3) {
      setCurrentSlide(2);
      if (eventType != "online-exam") {
        setConfigureSlide("grades");
      } else {
        setConfigureSlide("online-grades");
      }
    }

    if (currentSlide === 1 || currentSlide === 0) {
      if (!isEdit) {
        setEventType(null);
      }
      if (setGotType) {
        setGotType(false);
      }
    }
    sliderRef.current.slickPrev();
  };

  const trySubmitEvent = () => {
    if (eventType == "online-exam" || eventType == "parent-event") {
      submitEvent();
    } else if (eventType == "announcement") {
      submitAnnouncement();
    } else {
      setFinalTestForErrors(true);
    }
  };

  useEffect(() => {
    console.log(" no final errors ");
    if (noFinalErrors == "no-errors") {
      submitEvent();
    }
  }, [noFinalErrors]);

  const submitAnnouncement = () => {
    setSaveAnnouncementText(true);
  };

  const submitEvent = () => {
    setSaveRichText(true);
    if (eventType != "online-exam") {
      saveEvent();
    }
  };

  useEffect(() => {
    if (saveRichText) {
      let allSaved = true;
      onlineGrades.map((grade) => {
        if (!grade.saved) {
          allSaved = false;
        }
      });
      if (allSaved) {
        saveEvent();
      }
    }
  }, [onlineGrades]);

  useEffect(() => {
    if (saveAnnouncementText) {
      saveAnnouncement();
    }
  }, [announcementTitle]);

  const saveAnnouncement = () => {
    if (isEdit) {
      let body = {
        announcement_id: announcementId,
        title: "",
        description: announcementTitle,
        class_id: eventClass,
        department_id: JSON.stringify(eventDepartments),
        for_professors: true,
        for_students: true,
        for_parents: true,
        start_at: eventStartDate,
        finish_at: eventEndDate,
      };

      socketContext.socket.emit("editAnnouncement", body);
    } else {
      let body = {
        title: "",
        description: announcementTitle,
        class_id: eventClass,
        department_id: JSON.stringify(eventDepartments),
        for_professors: true,
        for_students: true,
        for_parents: true,
        start_at: eventStartDate,
        finish_at: eventEndDate,
      };
      console.log(body);

      socketContext.socket.emit("addAnnouncement", body);
    }
    setShowCreateEvent(false);
    if (isEdit) {
      setConfigureSlide("general");
    } else {
      setConfigureSlide("type");
    }
    setTestForErrors(false);
    setNoFinalErrors("testing");
  };

  const saveEvent = () => {
    const hash = window.crypto.randomUUID();
    let meeting_url = "https://meet.jit.si/" + hash;
    if (generalSettings && generalSettings.length) {
      let onlineSetting = generalSettings.find(
        (stn) => stn.name == "lectures_are_online"
      );
      if (!onlineSetting || !onlineSetting.value) {
        meeting_url = "";
      }
    }
    let eventBody = {
      title: eventTitle,
      start_at: eventStartDate,
      finish_at: eventEndDate,
      type: eventType,
      classroom_id: JSON.stringify(eventClassrooms),
      class_id: eventClass,
      department_id: JSON.stringify(
        eventDepartments && eventDepartments.length ? eventDepartments : [-1]
      ),
      repeat_type: eventRepeatType,
      classroom_name: eventClassroomName,
      professors: JSON.stringify(eventProfessors),
      meeting_url: meeting_url,
      duration: eventDuration,
      old_event: eventOld ? eventOld : false,
      parent_id: -1,
      is_phone: 0,
    };
    if (
      eventType == "exam" ||
      eventType == "test" ||
      eventType == "online-exam"
    ) {
      eventBody.create_also_exam = true;
      eventBody.is_test = eventType === "test" ? true : false;
      eventBody.is_online_exam = eventType === "online-exam" ? true : false;
      eventBody.questions_count = eventDetailedRank.length;
      eventBody.prof_id = JSON.stringify(eventProfessors);
      eventBody.max_score = eventMaxRank;
      if (eventType != "online-exam") {
        eventBody.detailed_rank = eventDetailedRank;
        eventBody.detailed_names = eventDetailedNames;
        eventBody.duration = eventDuration;
      } else {
        const tempGrades = onlineGrades.map((item) => item.rank);
        const tempDetails = onlineGrades.map((item) => item.description);
        eventBody.detailed_rank = tempGrades;
        eventBody.exam_details = encodeURIComponent(
          JSON.stringify(tempDetails)
        );
      }
    }

    const newEvent = {
      event_id: -1,
      title: eventTitle,
      start_at: eventStartDate,
      finish_at: eventEndDate,
      type: eventType,
      classroom_id: JSON.stringify(eventClassrooms),
      class_id: eventClass,
      department_id: JSON.stringify(
        eventDepartments && eventDepartments.length ? eventDepartments : [-1]
      ),
      repeat_type: eventRepeatType,
      classroom_name: eventClassroomName,
      professors: JSON.stringify(eventProfessors),
    };

    if (!isEdit) {
      setEvents([...events, newEvent]);
    }
    if (isEdit) {
      eventBody.event_id = eventId;
      eventBody.edit_occurrence = true;
      eventBody.exam_id = eventExamId;
      eventBody.duration = eventDuration;
      console.log(eventBody);
      socketContext.socket.emit("updateEvent", eventBody);
    } else {
      console.log(eventBody);
      socketContext.socket.emit("addEvent", eventBody);
    }

    setShowCreateEvent(false);
    if (isEdit) {
      setConfigureSlide("general");
    } else {
      setConfigureSlide("type");
    }
    setTestForErrors(false);
    setNoFinalErrors("testing");
  };

  // useEffect(() => {
  //   setConfigureSlide("type");
  // }, [showCreateEvent]);

  return (
    <div
      className={
        "configure-event " +
        (eventType ? "got-type" : "") +
        " " +
        configureSlide
      }
    >
      {noFinalErrors == "has-error" ? (
        <div className="modal-background show big"></div>
      ) : (
        ""
      )}
      {noFinalErrors == "has-error" ? (
        <div className="configure-event__error">
          <div className="configure-event__error-box">
            <div className="box-header">
              <span className="title">{errorMsg}</span>
              <span className="label">
                Είστε σίγουροι ότι θέλετε να δημιουργήσετε αυτό το μάθημα?
              </span>
              <div className="box-actions">
                <button
                  className="cta cta-red"
                  onClick={() => {
                    setNoFinalErrors("testing");
                    setFinalTestForErrors(false);
                  }}
                >
                  Ακύρωση
                </button>
                <button className="cta" onClick={() => submitEvent()}>
                  Αποθήκευση
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Slider className="configure-event__slider" ref={sliderRef} {...settings}>
        {!isEdit ? (
          <div className="section section__type">
            <div className="list">
              {showLecture &&
              getGeneralSettingValue(
                generalSettings,
                "announcement_calendar"
              ) ? (
                <div
                  className="item announcement"
                  onClick={() => {
                    nextSlide("announcement");
                  }}
                >
                  <div className="item-logo">
                    <div className="img-wrapper">
                      <img src="/resources/classroom-icon.png" alt="Μάθημα" />
                    </div>
                  </div>
                  <div className="item-text">
                    <span className="item-sublabel">Δημιούργησε</span>
                    <span className="item-label">Ανακοίνωση</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {showLecture ? (
                <div
                  className={
                    "item lecture " +
                    (getGeneralSettingValue(
                      generalSettings,
                      "announcement_calendar"
                    )
                      ? "item-small"
                      : "")
                  }
                  onClick={() => {
                    nextSlide("lecture");
                  }}
                >
                  <span className="item-sublabel">Δημιούργησε</span>
                  <span className="item-label">Μάθημα</span>
                  <div className="item-logo">
                    <div className="img-wrapper">
                      <img src="/resources/classroom-icon.png" alt="Μάθημα" />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  "item test " +
                  (getGeneralSettingValue(
                    generalSettings,
                    "announcement_calendar"
                  )
                    ? "item-small"
                    : "")
                }
                onClick={() => {
                  nextSlide("test");
                }}
              >
                <span className="item-sublabel">Δημιούργησε</span>
                <span className="item-label">Τεστ</span>
                <div className="item-logo">
                  <div className="img-wrapper">
                    <img src="/resources/test-icon.png" alt="Τεστ" />
                  </div>
                </div>
              </div>
              <div
                className={
                  "item exam " +
                  (getGeneralSettingValue(
                    generalSettings,
                    "announcement_calendar"
                  )
                    ? "item-small"
                    : "")
                }
                onClick={() => {
                  nextSlide("exam");
                }}
              >
                <span className="item-sublabel">Δημιούργησε</span>
                <span className="item-label">Διαγώνισμα</span>
                <div className="item-logo">
                  <div className="img-wrapper">
                    <img src="/resources/exam-icon.png" alt="Διαγώνισμα" />
                  </div>
                </div>
              </div>
              <div
                className={
                  "item online-exam " +
                  (getGeneralSettingValue(
                    generalSettings,
                    "announcement_calendar"
                  )
                    ? "item-small"
                    : "")
                }
                onClick={() => {
                  nextSlide("online-exam");
                }}
              >
                <span className="item-sublabel">Δημιούργησε</span>
                <span className="item-label">e-Διαγώνισμα</span>
                <div className="item-logo">
                  <div className="img-wrapper">
                    <img
                      src="/resources/online-exam-icon.png"
                      alt="Online διαγώνισμα"
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  "item parent-event " +
                  (getGeneralSettingValue(
                    generalSettings,
                    "announcement_calendar"
                  )
                    ? "parent-event-small"
                    : "") +
                  (profile.user_type == "admin" || profile.is_secretary
                    ? " "
                    : " not-visible")
                }
                onClick={() => {
                  nextSlide("parent-event");
                }}
              >
                <div className="item-logo">
                  <div className="img-wrapper">
                    <img src="/resources/classroom-icon.png" alt="Μάθημα" />
                  </div>
                </div>
                <div className="item-text">
                  <span className="item-sublabel">Δημιούργησε</span>
                  <span className="item-label">Ενημέρωση γονέων</span>
                </div>
              </div>
              {/* {profile.user_type == "admin" || profile.is_secretary ? (
                <div
                  className="item parent-event"
                  onClick={() => {
                    nextSlide("parent-event");
                  }}
                >
                  <div className="item-logo">
                    <div className="img-wrapper">
                      <img src="/resources/classroom-icon.png" alt="Μάθημα" />
                    </div>
                  </div>
                  <div className="item-text">
                    <span className="item-sublabel">Δημιούργησε</span>
                    <span className="item-label">Ενημέρωση γονέων</span>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        ) : (
          ""
        )}
        {eventType == "announcement" ? (
          <div className={"section "}>
            <AnnouncementSection eventType={eventType} isEdit={isEdit} />
          </div>
        ) : (
          <div className={"section "}>
            <GeneralSection eventType={eventType} isEdit={isEdit} />
          </div>
        )}
        {eventType !== "lecture" &&
        eventType !== "announcement" &&
        eventType !== "parent-event" &&
        eventType !== "online-exam" ? (
          <div className={"section "}>
            <GradesSection eventType={eventType} />
          </div>
        ) : (
          ""
        )}
        {eventType == "online-exam" ? (
          <div className={"section "}>
            <OnlineGradesSection eventType={eventType} />
          </div>
        ) : (
          ""
        )}
        {eventType == "online-exam" || eventType == "announcement" ? (
          <div className={"section "}>
            <OnlineDateSection
              eventType={eventType}
              calendarDate={selectedDate}
            />
          </div>
        ) : eventType == "parent-event" ? (
          <div className={"section "}>
            <OnlineDateSection
              eventType={eventType}
              calendarDate={selectedDate}
            />
          </div>
        ) : (
          <div className={"section "}>
            <DateSection eventType={eventType} calendarDate={selectedDate} />
          </div>
        )}
      </Slider>
      {(eventType && eventType != null) || isEdit ? (
        <div
          className={
            "configure-event__actions " +
            (noFinalErrors == "has-error" ? "under" : "")
          }
        >
          <div className="steps">
            {eventType === "lecture" ||
            eventType == "announcement" ||
            eventType == "parent-event" ? (
              <div className="steps__list">
                <div
                  className={
                    "step " +
                    ((currentSlide > 1 && !isEdit) ||
                    (isEdit && currentSlide > 0)
                      ? " active"
                      : "") +
                    ((currentSlide == 1 && !isEdit) ||
                    (isEdit && currentSlide == 0)
                      ? " current"
                      : "")
                  }
                >
                  1
                </div>
                <div
                  className={
                    "step " +
                    ((!isEdit && currentSlide > 2) ||
                    (isEdit && currentSlide > 1)
                      ? " active"
                      : "") +
                    ((currentSlide == 2 && !isEdit) ||
                    (isEdit && currentSlide == 1)
                      ? " current"
                      : "")
                  }
                >
                  2
                </div>
              </div>
            ) : (
              ""
            )}
            {eventType !== "lecture" &&
            eventType != "announcement" &&
            eventType != "parent-event" ? (
              <div className="steps__list">
                <div
                  className={
                    "step " +
                    ((!isEdit && currentSlide > 1) ||
                    (isEdit && currentSlide > 0)
                      ? " active"
                      : "") +
                    ((!isEdit && currentSlide == 1) ||
                    (isEdit && currentSlide == 0)
                      ? " current"
                      : "")
                  }
                >
                  1
                </div>
                <div
                  className={
                    "step " +
                    ((currentSlide > 2 && !isEdit) ||
                    (isEdit && currentSlide > 1)
                      ? " active"
                      : "") +
                    ((currentSlide == 2 && !isEdit) ||
                    (isEdit && currentSlide == 1)
                      ? " current"
                      : "")
                  }
                >
                  2
                </div>
                <div
                  className={
                    "step " +
                    ((currentSlide > 3 && !isEdit) ||
                    (isEdit && currentSlide > 2)
                      ? " active"
                      : "") +
                    ((currentSlide == 3 && !isEdit) ||
                    (isEdit && currentSlide == 2)
                      ? "current"
                      : "")
                  }
                >
                  3
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            className={
              "cta cta-shadow prev " +
              (isUploading ? " uploading" : "") +
              (!isEdit || currentSlide > 0 ? "" : " not-visible")
            }
            onClick={() => prevSlide()}
          >
            <span>Προηγούμενο</span>
            <div className="icon">
              <BiArrowBack
                size={"30px"}
                color={"#6225e6"}
                style={iconStyle("transparent")}
              />
            </div>
          </button>
          {(eventType === "lecture" ||
            eventType == "announcement" ||
            eventType == "parent-event") &&
          (currentSlide === 2 || (currentSlide === 1 && isEdit)) ? (
            <button
              className={
                "cta  cta-shadow final " + (isUploading ? " uploading" : "")
              }
              onClick={() => trySubmitEvent()}
            >
              <span>
                {" "}
                {isUploading ? "Φόρτωση" : isEdit ? "Αποθήκευση" : "Δημιουργία"}
              </span>
              <div className="icon">
                <BiSave
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
              </div>
            </button>
          ) : (
            ""
          )}
          {(eventType !== "lecture" && currentSlide) === 3 ||
          (eventType !== "lecture" && isEdit && currentSlide == 2) ? (
            <button
              className={
                "cta  cta-shadow final " + (isUploading ? " uploading" : "")
              }
              onClick={() => trySubmitEvent()}
            >
              {isEdit ? "Αποθήκευση" : "Δημιουργία"}
            </button>
          ) : (
            ""
          )}
          {((eventType === "lecture" ||
            eventType == "announcement" ||
            eventType == "parent-event") &&
            currentSlide < 2 &&
            !isEdit) ||
          ((eventType === "lecture" ||
            eventType == "announcement" ||
            eventType == "parent-event") &&
            isEdit &&
            currentSlide < 1) ? (
            <button className="cta  cta-shadow" onClick={() => tryNextSlide()}>
              <span>Επόμενο</span>
              <div className="icon right">
                <BiArrowBack
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
              </div>
            </button>
          ) : (
            ""
          )}
          {eventType !== "lecture" &&
          eventType !== "announcement" &&
          eventType != "parent-event" &&
          currentSlide < 3 &&
          (!isEdit || currentSlide < 2) ? (
            <button className="cta  cta-shadow" onClick={() => tryNextSlide()}>
              <span>Επόμενο</span>
              <div className="icon right">
                <BiArrowBack
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
              </div>
            </button>
          ) : (
            ""
          )}
          {isUploading ? <div class="circle-loader"></div> : ""}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ConfigureEvent;

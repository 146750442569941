import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAlignMiddle,
  BiPencil,
  BiPlus,
  BiPrinter,
  BiUpload,
  BiUserPin,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./print-section.scss";
import ClassAccordion from "../../../../components/ClassAccordion/classAccordion";
import SelectUsers from "../../../../components/SelectUsers/selectUsers";
import { usePrintSectionContext } from "./printSectionContext";

function PrintSection({}) {
  const socketContext = useContext(SocketContext);
  const {
    grades,
    setGrades,
    selectedGrades,
    setSelectedGrades,
    classes,
    setClasses,
    selectedClasses,
    setSelectedClasses,
    departments,
    setDepartments,
    selectedDepartments,
    setSelectedDepartments,
    users,
    setUsers,
  } = usePrintSectionContext();

  return (
    <div className="print-section">
      <SelectUsers />
    </div>
  );
}

export default PrintSection;

import React, { createContext, useState, useContext, useEffect } from "react";

// Step 1: Create the context
export const PrintSectionContext = createContext();

// Step 2: Create a provider component
export const PrintSectionProvider = ({ type, children }) => {
  const [grades, setGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [departmentHasUsers, setDepartmentHasUsers] = useState([]);
  const [usersInDepartments, setUsersInDepartments] = useState([]);
  const [openClass, setOpenClass] = useState();
  const [openGrade, setOpenGrade] = useState();
  const [openDepartment, setOpenDepartment] = useState();

  const handleGradeClick = (gradeId) => {
    if (selectedGrades.includes(gradeId)) {
      console.log("here");
      setSelectedGrades((prev) => prev.filter((id) => id !== gradeId));

      const classesWithGrade = classes
        .filter((cls) => cls.grade_id == gradeId)
        .map((cls) => cls.class_id);
      const departmentsWithGrade = departments
        .filter((dep) => classesWithGrade.includes(dep.class_id))
        .map((dep) => dep.department_id);

      const usersWithGrade = users
        .filter((usr) =>
          departmentHasUsers.find(
            (dep_users) =>
              dep_users.user_id == usr.user_id &&
              departmentsWithGrade.includes(
                (dep) => dep == dep_users.department_id
              )
          )
        )
        .map((usr) => usr.user_id);

      setSelectedClasses((prev) =>
        prev.filter((id) => !classesWithGrade.includes(id))
      );
      setSelectedDepartments((prev) =>
        prev.filter((id) => !departmentsWithGrade.includes(id))
      );
      // setSelectedUsers((prev) =>
      //   prev.filter((id) => !usersWithGrade.includes(id))
      // );
    } else {
      setSelectedGrades((prev) => [...prev.filter((id) => id !== -1), gradeId]);
      const newClassIds = classes
        .filter((cls) => cls.grade_id === gradeId)
        .map((cls) => cls.class_id);

      setSelectedClasses((prev) => {
        const combined = [...prev, ...newClassIds];
        return [...new Set(combined)];
      });

      const newDepartmentIds = departments
        .filter((dep) => newClassIds.includes(dep.class_id))
        .map((dep) => dep.department_id);

      setSelectedDepartments((prev) => {
        const combined = [...prev, ...newDepartmentIds];
        return [...new Set(combined)];
      });

      const newUserIds = users
        .filter((usr) =>
          newDepartmentIds.find((depId) =>
            departmentHasUsers.find(
              (dep_users) =>
                dep_users.user_id == usr.user_id &&
                depId == dep_users.department_id
            )
          )
        )
        .map((usr) => usr.user_id);

      // setSelectedUsers((prev) => {
      //   const combined = [...prev, ...newUserIds];
      //   return [...new Set(combined)];
      // });
    }
  };

  const handleClassClick = (classId) => {
    if (selectedClasses.includes(classId)) {
      setSelectedGrades((prev) => prev.filter((id) => id !== openGrade));

      setSelectedClasses((prev) => prev.filter((id) => id !== classId));

      const departmentsWithClass = departments
        .filter((dep) => dep.class_id == classId)
        .map((dep) => dep.department_id);
      const usersWithClass = users
        .filter((usr) =>
          departmentHasUsers.find(
            (dep_users) =>
              dep_users.user_id == usr.user_id &&
              departmentsWithClass.includes(
                (dep) => dep == dep_users.department_id
              )
          )
        )
        .map((usr) => usr.user_id);

      setSelectedDepartments((prev) =>
        prev.filter((id) => !departmentsWithClass.includes(id))
      );
      setSelectedUsers((prev) =>
        prev.filter((id) => !usersWithClass.includes(id))
      );
    } else {
      const filteredClasses = classes
        .filter((cls) => cls.grade_id == openGrade)
        .map((cls) => cls.class_id);

      const filteredClassesSelected = classes
        .filter(
          (cls) =>
            cls.grade_id == openGrade && selectedClasses.includes(cls.class_id)
        )
        .map((cls) => cls.class_id);

      if (filteredClasses.length == filteredClassesSelected.length + 1) {
        setSelectedGrades((prev) => [
          ...prev.filter((id) => id !== -1),
          openGrade,
        ]);
      }
      setSelectedClasses((prev) => [
        ...prev.filter((id) => id !== -1),
        classId,
      ]);
      const newDepartmentIds = departments
        .filter((dep) => dep.class_id === classId)
        .map((dep) => dep.department_id);

      setSelectedDepartments((prev) => {
        const combined = [...prev, ...newDepartmentIds];
        return [...new Set(combined)];
      });

      const newUserIds = users
        .filter((usr) =>
          newDepartmentIds.find((depId) =>
            departmentHasUsers.find(
              (dep_users) =>
                dep_users.user_id == usr.user_id &&
                depId == dep_users.department_id
            )
          )
        )
        .map((usr) => usr.user_id);

      setSelectedUsers((prev) => {
        const combined = [...prev, ...newUserIds];
        return [...new Set(combined)];
      });
    }
  };

  const handleDepartmentClick = (departmentId) => {
    if (selectedDepartments.includes(departmentId)) {
      setSelectedGrades((prev) => prev.filter((id) => id !== openGrade));
      setSelectedClasses((prev) => prev.filter((id) => id !== openClass));

      setSelectedDepartments((prev) =>
        prev.filter((id) => id !== departmentId)
      );

      const usersWithDepartment = users
        .filter((usr) =>
          departmentHasUsers.find(
            (dep_users) =>
              dep_users.user_id == usr.user_id &&
              dep_users.department_id == departmentId
          )
        )
        .map((usr) => usr.user_id);

      setSelectedUsers((prev) =>
        prev.filter((id) => !usersWithDepartment.includes(id))
      );
    } else {
      setSelectedDepartments((prev) => [
        ...prev.filter((id) => id !== -1),
        departmentId,
      ]);

      const newUserIds = users
        .filter((usr) =>
          departmentHasUsers.includes(
            (dep_users) =>
              dep_users.user_id == usr.user_id &&
              dep_users.department_id == departmentId
          )
        )
        .map((usr) => usr.user_id);

      setSelectedUsers((prev) => {
        const combined = [...prev, ...newUserIds];
        return [...new Set(combined)];
      });
    }
  };

  const handleUserClick = (userId, openDepartment, openClass, openGrade) => {
    setUsersInDepartments((prev) => {
      if (!prev[openDepartment]) return prev;

      const isUserInDept = prev[openDepartment].includes(userId);

      return {
        ...prev,
        [openDepartment]: isUserInDept
          ? prev[openDepartment].filter((id) => id !== userId)
          : [...prev[openDepartment], userId],
      };
    });
  };

  useEffect(() => {
    const departmentMap = departments.reduce((acc, dept) => {
      acc[dept.department_id] = [];
      return acc;
    }, {});

    setUsersInDepartments(departmentMap);
  }, [departments]);

  useEffect(() => {
    selectedDepartments.forEach((dp) => {
      const department = departments.find(
        (department) => department.department_id == dp
      );

      if (!department) return; // Safety check

      const dep = department.department_id;

      const usersFiltered = users.filter((usr) =>
        departmentHasUsers.some(
          (dep_users) =>
            dep_users.department_id == dp && dep_users.user_id == usr.user_id
        )
      );

      setUsersInDepartments((prev) => ({
        ...prev,
        [dep]: usersFiltered.map((usr) => usr.user_id), // Use computed property
      }));
    });
  }, [selectedDepartments]);

  return (
    <PrintSectionContext.Provider
      value={{
        grades,
        setGrades,
        selectedGrades,
        setSelectedGrades,
        classes,
        setClasses,
        selectedClasses,
        setSelectedClasses,
        departments,
        setDepartments,
        selectedDepartments,
        setSelectedDepartments,
        users,
        setUsers,
        selectedUsers,
        setSelectedUsers,
        departmentHasUsers,
        setDepartmentHasUsers,
        handleGradeClick,
        handleClassClick,
        handleDepartmentClick,
        handleUserClick,
        usersInDepartments,
        setUsersInDepartments,
        openGrade,
        setOpenGrade,
        openClass,
        setOpenClass,
        openDepartment,
        setOpenDepartment,
      }}
    >
      {children}
    </PrintSectionContext.Provider>
  );
};

// Step 3: Create a custom hook for easier consumption of the context
export const usePrintSectionContext = () => {
  const context = useContext(PrintSectionContext);
  if (!context) {
    throw new Error(
      "useEventContext must be used within an PrintSectionProvider"
    );
  }
  return context;
};

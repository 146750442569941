import React, { useState, useEffect, useContext, useRef } from "react";
import "./general-section.scss";
import { SocketContext } from "../../../../app/socket";
import { useEventContext } from "../../eventContext";
import { getGeneralSettingValue } from "../../../../utils/generalUtils";
import { useSelector } from "react-redux";
import ClassSelector from "../../../ClassSelector/classSelector";

function GeneralSection({ eventType, isEdit }) {
  const socketContext = useContext(SocketContext);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [classes, setClasses] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [searchClass, setSearchClass] = useState("");

  const { eventTitle, setEventTitle } = useEventContext();
  const { eventClass, setEventClass } = useEventContext();
  const { eventDepartments, setEventDepartments } = useEventContext();
  const { eventProfessors, setEventProfessors } = useEventContext();

  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);

  const { testForErrors, setTestForErrors } = useEventContext();
  const { setNoErrors } = useEventContext();

  const eventClassRef = useRef(0);
  const eventTypeRef = useRef(0);

  const [professors, setProfessors] = useState([]);

  const [showTitleError, setShowTitleError] = useState(false);
  const [showDepartmentError, setShowDepartmentError] = useState(false);
  const [showProfError, setShowProfError] = useState(false);

  const firstTimeRef = useRef(0);

  const activeClassRef = useRef(null); // Ref for the active class element
  const classesContainerRef = useRef(null); // Ref for the classes container

  useEffect(() => {
    if (eventType == "parent-event") {
      const classesToInclude = [];

      if (selectedGrades != "[-1]") {
        selectedGrades.map((grd) => {
          const filteredClasses = classes.filter((cls) => cls.grade_id == grd);
          filteredClasses.map((cls) => {
            classesToInclude.push(cls.class_id);
          });
        });
      }

      selectedClasses.map((cls) => {
        classesToInclude.push(cls);
      });

      setEventClass(JSON.stringify(classesToInclude));
    }
  }, [selectedGrades, selectedGrades]);

  useEffect(() => {
    if (eventType == "parent-event") {
      setEventClass(JSON.stringify(selectedClasses));
    }
  }, [selectedClasses]);

  useEffect(() => {
    if (firstTimeRef.current > 0 && testForErrors) {
      if (
        eventTitle &&
        (eventDepartments.length || eventType == "parent-event") &&
        (eventProfessors.length || eventType == "parent-event")
      ) {
        setShowTitleError(false);
        setShowDepartmentError(false);
        setShowProfError(false);

        setNoErrors(true);
      } else {
        if (!eventTitle) {
          setShowTitleError(true);
        }
        if (!eventDepartments.length && eventType != "parent-event") {
          setShowDepartmentError(true);
        }
        if (!eventProfessors.length && eventType != "parent-event") {
          setShowProfError(true);
        }
        if (eventType == "parent-event") {
          setShowProfError(false);
        }
        setTestForErrors(false);
      }
    } else {
      firstTimeRef.current++;
    }
  }, [testForErrors]);

  useEffect(() => {
    if (eventTitle) {
      setShowTitleError(false);
      setTestForErrors(false);
    } else {
      setTestForErrors(false);
    }
  }, [eventTitle]);

  useEffect(() => {
    if (eventDepartments.length) {
      setShowDepartmentError(false);
      setTestForErrors(false);
    } else {
      setTestForErrors(false);
    }
  }, [eventDepartments]);

  useEffect(() => {
    if (eventProfessors.length) {
      setShowProfError(false);
      setTestForErrors(false);
    } else {
      setTestForErrors(false);
    }
  }, [eventProfessors]);

  useEffect(() => {
    if (eventType == "parent-event") {
      setEventTitle("Ενημέρωση Γονέων");
      setEventDepartments("[-1]");
    }
    if (eventTypeRef.current > 0 && !isEdit) {
      setEventProfessors([]);
      setEventDepartments([]);
    }
    eventTypeRef.current++;
  }, [eventType]);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, [searchClass]);

  useEffect(() => {
    if (eventClassRef.current > 0) {
      setEventDepartments([]);
      if (eventType != "parent-event") {
        setEventProfessors([]);
      }
    }
    eventClassRef.current++;
    const cleanUpGetDepartments = getDepartments();
    const cleanUpGetProfessors = getProfessors();
    return () => {
      cleanUpGetDepartments();
      cleanUpGetProfessors();
    };
  }, [eventClass]);

  useEffect(() => {
    if (isEdit && activeClassRef.current) {
      setTimeout(() => {
        activeClassRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 200);
    }
  }, [classes, eventClass, isEdit]);

  const getClasses = () => {
    let args = { search: searchClass };

    const getClassesListener = (data) => {
      if (data && data[0] && (!eventClass || eventClass === -1)) {
        setEventClass(data[0].class_id);
        let temp = [];
        temp.push(data[0].class_id);
        setSelectedClasses(temp);
      }
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const populateClasses = () => {
    return classes.map((classItem, i) => {
      return (
        <div
          key={"announcementClassItem" + i}
          onClick={() => setEventClass(classItem.class_id)}
          className={
            "classes__list-item " +
            (eventClass === classItem.class_id ? "active" : "")
          }
          ref={eventClass === classItem.class_id ? activeClassRef : null}
        >
          <span className="class-name">{classItem.class_name}</span>
          {getGeneralSettingValue(generalSettings, "only_grades") ? (
            ""
          ) : (
            <span className="grade">{classItem.grade_name}</span>
          )}
        </div>
      );
    });
  };

  const getDepartments = () => {
    const getDepartmentsListener = (data) => {
      setDepartments(data);
    };

    socketContext.socket.on("departments", getDepartmentsListener);

    let args = { class_id: eventClass };
    socketContext.socket.emit("getDepartments", args);

    const refreshDepartmentsListener = () => {
      socketContext.socket.emit("getDepartments", args);
    };
    socketContext.socket.on("refreshDepartments", refreshDepartmentsListener);

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getDepartments", getDepartmentsListener);
      socketContext.socket.off("departments", getDepartmentsListener);
      socketContext.socket.off(
        "refreshDepartments",
        refreshDepartmentsListener
      );
    };
  };

  const populateDepartments = () => {
    return departments.map((departmentItem, i) => {
      return (
        <div
          key={"announcementDepartmentItem" + i}
          className={
            "item " +
            (eventDepartments.includes(departmentItem.department_id)
              ? "active"
              : "")
          }
          onClick={() => departmentClick(departmentItem.department_id)}
        >
          {departmentItem.department_name}
        </div>
      );
    });
  };

  const departmentClick = (clickedId) => {
    let temp = eventDepartments;
    if (eventType === "lecture" && eventDepartments.length) {
      setEventDepartments([]);
      temp = [];
    }
    if (eventDepartments.length === 1 && eventDepartments[0] === -1) {
      temp = [];
    }
    if (eventDepartments.includes(clickedId)) {
      const newDepartmentId = eventDepartments.filter((id) => id !== clickedId);
      if (newDepartmentId.length === 0) {
        if (eventType !== "lecture") {
          temp = [-1];
        }
      } else {
        temp = newDepartmentId;
      }
    } else {
      temp = [...temp, clickedId];
    }
    setEventDepartments(temp);
  };

  const getProfessors = () => {
    let args = {};
    if (eventType === "exam") {
      args = {
        classId: eventClass,
        type: "professor",
        also_admins: true,
      };
    } else {
      args = {
        classId: eventType != "parent-event" ? eventClass : -1,
        type: "professor",
        also_admins: true,
      };
    }

    const getProfessorsListener = (data) => {
      setProfessors(data);
    };

    socketContext.socket.on("allUsersWithParams", getProfessorsListener);
    socketContext.socket.emit("getAllUsersWithParams", args);

    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off("allUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const populateProfessors = () => {
    return professors.map((usr, i) => {
      return (
        <div
          key={"professorsItemNewEvent" + i}
          className={
            "item " + (eventProfessors.includes(usr.user_id) ? "active" : "")
          }
          onClick={() => professorClick(usr.user_id)}
        >
          <div className="item__img">
            <img
              alt={"student"}
              src={
                usr.profile_picture
                  ? usr.profile_picture
                  : "resources/student.png"
              }
            />
          </div>
          <div className="item__name">
            <span>{usr.first_name}</span>
            <span>{usr.last_name}</span>
          </div>
        </div>
      );
    });
  };

  const professorClick = (clickedId) => {
    let temp = eventProfessors;
    if (eventType === "lecture" && eventProfessors.length) {
      setEventProfessors([]);
      temp = [];
    }
    if (eventProfessors.length === 1 && eventProfessors[0] === -1) {
      temp = [];
    }
    if (eventProfessors.includes(clickedId)) {
      const newSelectedProfessors = eventProfessors.filter(
        (id) => id !== clickedId
      );
      if (newSelectedProfessors.length === 0) {
        if (eventType !== "lecture") {
          temp = [-1];
        }
      } else {
        temp = newSelectedProfessors;
      }
    } else {
      temp = [...temp, clickedId];
    }
    setEventProfessors(temp);
  };

  return (
    <div className={"general-section no-scrollbar"}>
      <span className="title"></span>
      <div className="left">
        {eventType != "parent-event" ? (
          <div className="section__input title-config">
            <span className="label">
              Τίτλος{" "}
              {showTitleError ? (
                <span className="error-msg">Εισάγετε τίτλο</span>
              ) : (
                ""
              )}
            </span>
            <input
              className="input"
              placeholder="Εισάγετε έναν τίτλο"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}
        {!getGeneralSettingValue(generalSettings, "only_grades") &&
        eventType != "parent-event" ? (
          <ClassSelector
            classes={classes}
            selectedClasses={selectedClasses}
            setSelectedClasses={setSelectedClasses}
            selectedGrades={selectedGrades}
            setSelectedGrades={setSelectedGrades}
            setSelectedClassId={setEventClass}
            isSingle={true}
          />
        ) : eventType != "parent-event" ? (
          <span className="label">
            Επιλέξτε{" "}
            {getGeneralSettingValue(generalSettings, "only_grades")
              ? "τάξη"
              : "μάθημα"}
          </span>
        ) : (
          ""
        )}
        {eventType == "parent-event" ? (
          <ClassSelector
            classes={classes}
            selectedClasses={selectedClasses}
            setSelectedClasses={setSelectedClasses}
            selectedGrades={selectedGrades}
            setSelectedGrades={setSelectedGrades}
          />
        ) : (
          ""
        )}
        {getGeneralSettingValue(generalSettings, "only_grades") ? (
          <div className="classes section__input" ref={classesContainerRef}>
            <input
              className="input"
              value={searchClass}
              onChange={(e) => setSearchClass(e.target.value)}
              placeholder={
                "Αναζητήστε " +
                (getGeneralSettingValue(generalSettings, "only_grades")
                  ? "τάξη"
                  : "μάθημα")
              }
            />
            <div className="classes__list">{populateClasses()}</div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="right">
        {eventType != "parent-event" ? (
          <div className="departments section__input">
            <span className="label">
              {eventType === "lecture" ? "Επιλέξτε Τμήμα" : "Επιλέξτε Τμήματα"}
              {showDepartmentError ? (
                <span className="error-msg">Επιλέξτε τμήμα</span>
              ) : (
                ""
              )}
            </span>
            <div className="departments__list custom-horizontal-scrollbar">
              {eventType !== "lecture" ? (
                <div
                  className={"item " + (eventDepartments == -1 ? "active" : "")}
                  onClick={() => setEventDepartments([-1])}
                >
                  Όλα
                </div>
              ) : (
                ""
              )}
              {populateDepartments()}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {eventType != "parent-event" ? (
          <div className="professors section__input">
            <span className="label">
              {eventType === "lecture"
                ? "Επιλέξτε Καθηγητή"
                : "Επιλέξτε Καθηγητές"}
              {showProfError ? (
                <span className="error-msg">Επιλέξτε καθηγητή</span>
              ) : (
                ""
              )}
            </span>
            <div className="professors__list">{populateProfessors()}</div>
          </div>
        ) : (
          ""
        )} */}
        <div className="professors section__input">
          <span className="label">
            {eventType === "lecture"
              ? "Επιλέξτε Καθηγητή"
              : "Επιλέξτε Καθηγητές"}
            {showProfError ? (
              <span className="error-msg">Επιλέξτε καθηγητή</span>
            ) : (
              ""
            )}
          </span>
          <div className="professors__list">{populateProfessors()}</div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSection;

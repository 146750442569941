import React from "react";
import "./inspector.scss";
import InspectorExamStats from "./inspectorExamStats";
import InspectorTextEditor from "./InspectorTextEditor";

const Inspector = ({ item, onUpdateItem, onStopEditing }) => {
  console.log("item ");
  console.log(item);
  // Whenever content changes, immediately update the item in parent state
  const handleContentChange = (e) => {
    onUpdateItem({
      ...item,
      content: e.target.value,
    });
  };

  const handleImgFit = (e) => {
    onUpdateItem({
      ...item,
      style: {
        ...item.style,
        objectFit: e.target.value,
      },
    });
  };

  // Same for textAlign
  const handleHorizontalAlignment = (e) => {
    onUpdateItem({
      ...item,
      style: {
        ...item.style,
        justifyContent: e.target.value,
      },
    });
  };

  const handleVerticalAlignment = (e) => {
    onUpdateItem({
      ...item,
      style: {
        ...item.style,
        alignItems: e.target.value,
      },
    });
  };

  const handleLineColorChange = (e) => {
    onUpdateItem({
      ...item,
      line_color: e.target.value,
    });
  };

  // And for backgroundColor
  const handleBackgroundColorChange = (e) => {
    onUpdateItem({
      ...item,
      style: {
        ...item.style,
        backgroundColor: e.target.value,
      },
    });
  };

  const handleTextColorChange = (e) => {
    onUpdateItem({
      ...item,
      style: {
        ...item.style,
        color: e.target.value,
      },
    });
  };

  return (
    <div className="inspector">
      <div className="inspector__back">
        <button className="cta cta-fill" onClick={onStopEditing}>
          Προσθήκη
        </button>
      </div>

      {item.type == "text" ? (
        <div>
          <InspectorTextEditor
            text={item.content || ""}
            setText={(newHtml) =>
              onUpdateItem({
                ...item,
                content: newHtml,
              })
            }
          />
        </div>
      ) : (
        ""
      )}

      {item.type == "image" ? (
        <div className="inspector-item">
          <span>Προσαρμογή Φωτογραφίας:</span>
          <select
            className="input"
            value={item.style?.objectFit || "default"} // ✅ Use objectFit instead of justifyContent
            onChange={handleImgFit}
          >
            <option value="default">Default</option>
            <option value="cover">Cover</option>
            <option value="contain">Contain</option>
            <option value="fill">Fill</option>
            <option value="none">None</option>
            <option value="scale-down">Scale Down</option>
          </select>
        </div>
      ) : (
        ""
      )}

      {item.type == "exam-graph" ? (
        <div className="inspector-item">
          <span>Χρώμα Γραμμής:</span>
          <input
            className="color-input"
            type="color"
            value={item.line_color ? item.line_color : "#6225e6"}
            onChange={handleLineColorChange}
          />
        </div>
      ) : (
        ""
      )}

      {item.type == "exam-graph" ? (
        <div className="inspector-item">
          <span>Χρώμα Fade:</span>
          <input
            className="color-input"
            type="color"
            value={item.backdrop ? item.backdrop : "#6225e6"}
            onChange={(e) => {
              onUpdateItem({
                ...item,
                backdrop: e.target.value,
              });
            }}
          />
        </div>
      ) : (
        ""
      )}

      {item.type != "exam-graph" ? (
        <div className="inspector-item">
          <span>Οριζόντιο Κεντράρισμα:</span>
          <select
            className="input"
            value={item.style?.justifyContent || "flex-start"}
            onChange={handleHorizontalAlignment}
          >
            <option value="flex-start">Αριστερά</option>
            <option value="center">Κέντρο</option>
            <option value="flex-end">Δεξιά</option>
          </select>
        </div>
      ) : (
        ""
      )}

      {item.type != "exam-graph" ? (
        <div className="inspector-item">
          <span>Κάθετο Κεντράρισμα:</span>
          <select
            className="input"
            value={item.style?.alignItems || "flex-start"}
            onChange={handleVerticalAlignment}
          >
            <option value="flex-start">Πάνω</option>
            <option value="center">Κέντρο</option>
            <option value="flex-end">Κάτω</option>
          </select>
        </div>
      ) : (
        ""
      )}

      {item.type == "text" ? (
        <div className="inspector-item">
          <span>Χρώμα Φόντου:</span>
          <input
            className="color-input"
            type="color"
            value={item.style?.backgroundColor || "transparent"}
            onChange={handleBackgroundColorChange}
          />
        </div>
      ) : (
        ""
      )}

      {item.type == "text" ? (
        <div className="inspector-item">
          <span>Χρώμα Κειμένου:</span>
          <input
            className="color-input"
            type="color"
            value={item.style?.color}
            onChange={handleTextColorChange}
          />
        </div>
      ) : (
        ""
      )}

      {(item.type === "exam-stats" || item.type == "test-stats") && (
        <InspectorExamStats item={item} onUpdateItem={onUpdateItem} />
      )}
    </div>
  );
};

export default Inspector;
